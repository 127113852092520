import {
    UPDATE_MODAL_DEPOT_STATE
} from '../constants/utils'

export const depotModalStatusReducer = (state = {}, action) => {

    switch (action.type) {
        case UPDATE_MODAL_DEPOT_STATE:
            return { ...state, modalOpenStatus : action.payload}
    
        default:
            return state;
    }
}