import { Spinner } from 'react-bootstrap'

const FadeLoader = () => {
    return (
        <div>
            <Spinner 
                animation="border" 
                variant="primary"
                style={{
                    display: "inherit",
                    fontSize: "10px",
                    left: "50%",
                    margin: "0 auto",
                    overflow: "visible",
                    position: "fixed",
                    top: "50%",
                    zIndex: "9999",
                }}  
            />  
        </div>
    )
}

export default FadeLoader
