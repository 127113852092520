
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from './Loader'
import {getUrlSiteByEnv, dateFormatTexte} from '../utils/function';
import { saveLogActionUser } from '../store/actions/historiquesUserAction';
import { logout } from '../store/actions/authAction';
import { useState, useEffect } from 'react'
import { Modal } from "react-bootstrap";
import { setModalStatus } from "../store/actions/utils"

const Header = () => {
    const dispatch = useDispatch();
    const { modalOpenStatus } = useSelector((state) => state.depotModalStatus);

    const authenticatedUser = useSelector((state) => state.auth)
    const { loading, error, userInfo } = authenticatedUser
    const {depots, currentDepot} = useSelector((state) => state.depotsList);
    const logoutHandler = () =>{
        dispatch(logout())
    }
    
    const [searchTermDepot, setSearchTermDepot] = useState("");
    const [searchResultsDepot, setSearchResultsDepot] = useState([]);
    const handleChange = event => {
        setSearchTermDepot(event.target.value);
    };
    useEffect(() => {
        let results = [];
        if(depots){
            results = depots.filter(depots =>
                depots.nom.toLowerCase().includes(searchTermDepot)
            )
        }
        setSearchResultsDepot(results);
    }, [searchTermDepot, depots])
    return (
        <div className="header d-print-none">
            <div className="header-container">
                <div className="header-body">
                    <div className="header-body-left">
                        <ul className="navbar-nav">
                            <li className="nav-item navigation-toggler">
                                <a href="#" className="nav-link">
                                    <i className="ti-menu"></i>
                                </a>
                            </li>
                            <li className="nav-item">
                            <img 
                                className="logoSite"
                                src="media/image/logo.jpg" 
                                alt="logo"
                            />
                            <img 
                                className="mini-logo"
                                src="media/image/logo.png" 
                                alt="logo"
                            />
                                {/* <div className="header-search-form">
                                <div className="header-search-form"> <img src="media/image/logo_app.png" alt="logo" />
                                </div>
                            </li>
                            {/* <li className="nav-item">
                                <div className="header-search-form">
                                    <form>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <button className="btn">
                                                    <i className="ti-search"></i>
                                                </button>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Search something..." />
                                            <div className="input-group-append">
                                                <button className="btn header-search-close-btn">
                                                    <i className="fas fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div> */}
                            </li>
                        </ul>
                    </div>

                    <div className="header-body-right">
                        <ul className="navbar-nav">
                            {/* <li className="nav-item">
                                <a href="#" className="nav-link mobile-header-search-btn" title="Search">
                                    <i className="ti-search"></i>
                                </a>
                            </li> */}
                            {/* <li className="nav-item">
                                <a href="#" className="nav-link" title="Dark">
                                    <i className="fa fa-moon-o"></i>
                                </a>
                            </li> */}

                            <li className="nav-item dropdown">
                                <div className="nav-link" title="Mes depôts" onClick={() => dispatch(setModalStatus(true))} style={{cursor: 'pointer'}}>
                                    <i className="fa fa-folder" style={{color:"#ffc542"}}></i>
                                </div>
                                {modalOpenStatus && 
                                    <Modal id="mes-depots-modal" show={modalOpenStatus} onHide={() => dispatch(setModalStatus(false))} className="custom-map-modal" animation={true}>
                                        <Modal.Header closeButton>
                                        <Modal.Title>Mes depôts</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                        {depots && depots.length > 0 &&
                                            <input
                                                type="text"
                                                placeholder="Rechercher"
                                                value={searchTermDepot}
                                                onChange={handleChange}
                                                className="form-control mb-2"
                                            />
                                        }
                                        <ul className="list-group list-group-flush">
                                            {searchResultsDepot && searchResultsDepot.map(folder => 
                                            <li key={`modal${folder.id_dossier}`} className="list-group-item d-flex hide-show-toggler depot-element" style={{backgroundColor:`${currentDepot?.slug === folder.slug ? '#ebebeb' : '#fff'}`}}>
                                                <Link to={`/home?depot=${folder.id_dossier}`} onClick={() => {dispatch(setModalStatus(false));}} className="d-flex">
                                                <div className=" mr-2">
                                                    <i className="fa fa-folder" style={{color:"#ffc542"}}></i>
                                                </div>
                                                <div>
                                                    <div>
                                                        <div className="mb-0">
                                                            {currentDepot?.slug === folder.slug ?
                                                                <div>
                                                                    <span className="text-primary">{folder.nom}</span>
                                                                    <i className="fa fa-check font-size-11 position-absolute right-0 top-0 mr-3 mt-3"></i>
                                                                </div>:
                                                                
                                                                    <span className="text-primary" style={{fontSize:'13px'}}>{folder.nom}</span>
                                                                
                                                            }
                                                            <small className="ml-1 d-block">
                                                                {folder.description ?
                                                                    <div>{folder.description}</div>
                                                                    :
                                                                    <div>Crée le : {dateFormatTexte(folder.date_ajout)}</div>
                                                                }
                                                            </small>
                                                            {/*
                                                            {currentDepot?.slug === folder.slug &&
                                                                <span className="d-block">Derniére modification: {new Date(folder.date_update).toISOString().slice(0, 19).replace("T", " ")}</span>
                                                            }
                                                            */}
                                                        </div>
                                                    </div>
                                                </div>
                                                </Link>
                                            </li>
                                            )}
                                        </ul>
                                        </Modal.Body>
                                    </Modal>
                                }
                                <div id="mes-depots" className="dropdown-menu dropdown-menu-right dropdown-menu-big">
                                    <div className="bg-primary px-3 py-3">
                                        <h6 className="mb-0">Mes depôts</h6>
                                    </div>
                                    <div className="dropdown-scroll">
                                        {(depots && depots.length <=0) && 
                                            <p style={{textAlign: 'center',margin: '10px'}}>
                                                Pas de depôts trouvé !
                                            </p>
                                        }
                                        {depots && depots.length > 0 &&
                                            <input
                                                type="text"
                                                placeholder="Rechercher"
                                                value={searchTermDepot}
                                                onChange={handleChange}
                                                className="form-control"
                                            />
                                        }
                                        <ul className="list-group list-group-flush">
                                            {searchResultsDepot && searchResultsDepot.map(folder => 
                                                <li key={folder.id_dossier} className="list-group-item d-flex hide-show-toggler" style={{backgroundColor:`${currentDepot?.slug === folder.slug ? '#ebebeb' : '#fff'}`}}>
                                                    <div className=" mr-2">
                                                        <i className="ti-folder"></i>
                                                    </div>
                                                    <div>
                                                        <div className="mb-0">
                                                            {currentDepot?.slug === folder.slug ?
                                                                <div>
                                                                    <span className="text-primary">{folder.nom}</span>
                                                                    <i className="fa fa-check font-size-11 position-absolute right-0 top-0 mr-3 mt-3"></i>
                                                                </div>:
                                                                <Link to={`/home?depot=${folder.id_dossier}`} onClick={() => document.getElementById('mes-depots').classList.remove("show")}>
                                                                    <span className="text-primary">{folder.nom}</span>
                                                                </Link>
                                                            }
                                                            <small className="ml-1 d-block">
                                                                {folder.description ?
                                                                    <div>{folder.description}</div>
                                                                    :
                                                                    <div>Crée le : {dateFormatTexte(folder.date_ajout)}</div>
                                                                }
                                                            </small>
                                                            {/*
                                                            {currentDepot?.slug === folder.slug &&
                                                                <span className="d-block">Derniére modification: {new Date(folder.date_update).toISOString().slice(0, 19).replace("T", " ")}</span>
                                                            }
                                                            */}
                                                        </div>
                                                    </div>
                                                </li>
                                            )}
                                            {/* <li>
                                                <a href="#" className="list-group-item d-flex hide-show-toggler">
                                                    <div>
                                                        <figure className="avatar mr-3">
                                                            <span
                                                                className="avatar-title bg-secondary-bright text-secondary rounded-circle">
                                                                <i className="ti-server"></i>
                                                            </span>
                                                        </figure>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <p className="mb-0">
                                                            Your storage space is running low!
                                                            <i title="Mark as unread" data-toggle="tooltip"
                                                            className="hide-show-toggler-item fa fa-check font-size-11 position-absolute right-0 top-0 mr-3 mt-3"></i>
                                                        </p>
                                                        <span className="text-muted small">4 sec ago</span>
                                                    </div>
                                                </a>
                                            </li> */}
                                            {/* <li>
                                                <Link to="#" className="list-group-item d-flex hide-show-toggler">
                                                    <div className=" mr-2">
                                                        <i className="ti-folder"></i>
                                                    </div>
                                                    <div>
                                                        <p className="mb-0">
                                                            <span className="text-primary">Jonny Richie</span>crée le:
                                                            <i title="Mark as read" data-toggle="tooltip"
                                                            className="hide-show-toggler-item fa fa-circle-o font-size-11 position-absolute right-0 top-0 mr-3 mt-3"></i>
                                                        </p>
                                                        {/* <span className="text-muted small">20 min ago</span> 
                                                    </div>
                                                </Link>
                                            </li> 
                                            <li className="text-divider text-center small pb-2 px-3">
                                                <span>Old notifications</span>
                                            </li>
                                            <li>
                                                <a href="#" className="list-group-item d-flex hide-show-toggler">
                                                    <div>
                                                        <figure className="avatar mr-3">
                                                            <span
                                                                className="avatar-title bg-info-bright text-info rounded-circle">
                                                                <i className="fa fa-cloud-upload"></i>
                                                            </span>
                                                        </figure>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <p className="mb-0">
                                                            Upgrade plan
                                                            <i title="Mark as unread" data-toggle="tooltip"
                                                            className="hide-show-toggler-item fa fa-check font-size-11 position-absolute right-0 top-0 mr-3 mt-3"></i>
                                                        </p>
                                                        <span className="text-muted small">45 sec ago</span>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#"
                                                className="list-group-item d-flex hide-show-toggler">
                                                    <div>
                                                        <figure className="avatar mr-3">
                                                            <span
                                                                className="avatar-title bg-success-bright text-success rounded-circle">
                                                                <i className="ti-share"></i>
                                                            </span>
                                                        </figure>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <p className="mb-0">
                                                            A file has been shared
                                                            <i title="Mark as unread" data-toggle="tooltip"
                                                            className="hide-show-toggler-item fa fa-check font-size-11 position-absolute right-0 top-0 mr-3 mt-3"></i>
                                                        </p>
                                                        <span className="text-muted small">58 sec ago</span>
                                                    </div>
                                                </a>
                                            </li>*/}
                                        </ul> 
                                    </div>
                                    {/* <div className="px-3 py-2 text-right border-top">
                                        <ul className="list-inline small">
                                            <li className="list-inline-item mb-0">
                                                <a href="#">Mark All Read</a>
                                            </li>
                                        </ul>
                                    </div> */}
                                </div>
                            </li>

                            {/* <li className="nav-item dropdown">
                                <a href="#" className="nav-link" title="Settings" data-sidebar-target="#settings">
                                    <i className="ti-settings"></i>
                                </a>
                            </li> */}
                            {loading && <Loader />}
                            {error&& <p>{error}</p>}
                            {userInfo && 
                                <li className="nav-item dropdown">
                                    <a  href="#" className="nav-link profile-nav-link dropdown-toggle" 
                                        title="User menu" data-toggle="dropdown">
                                        <span className="mr-2 d-sm-inline d-none">{userInfo.nom_prenom}</span>
                                        <figure className="avatar avatar-sm">
                                            {userInfo.image ? 
                                                <img src={`${getUrlSiteByEnv()}/${process.env.REACT_APP_USER_IMAGE_FOLDER}/${userInfo.image}`} 
                                                    className="rounded-circle"
                                                    alt="avatar" />
                                                : 
                                                <i className="far fa-user rounded-circle"></i> 
                                            }
                                        </figure>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-big">
                                        <div className="text-center py-4"
                                            data-background-image="media/image/image1.jpg">
                                                    {userInfo.image ? 
                                                        <figure className="avatar avatar-xl">
                                                            <img src={`${getUrlSiteByEnv()}/${process.env.REACT_APP_USER_IMAGE_FOLDER}/${userInfo.image}`} 
                                                                className="rounded-circle"
                                                                alt="avatar" />
                                                        </figure>
                                                        : 
                                                        <i className="far fa-user rounded-circle"></i> 
                                                    }
                                                
                                            <h5 className="mb-0">{userInfo.nom_prenom}</h5>
                                        </div>
                                        <div className="list-group list-group-flush">
                                            <Link to="/profile" className="list-group-item">Mon profile</Link>
                                            <div onClick={logoutHandler} className="pointer list-group-item text-danger">Déconnexion</div>
                                        </div>
                                        {/* <div className="pb-0 p-4">
                                            <div className="mb-4">
                                                <h6 className="d-flex justify-content-between">
                                                    Completed Tasks
                                                    <span className="float-right">%68</span>
                                                </h6>
                                                <div className="progress" style={{height:'5px'}}>
                                                    <div className="progress-bar bg-primary" role="progressbar"
                                                        style={{width: "68%"}} aria-valuenow="68" aria-valuemin="0"
                                                        aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </li>
                            }
                        </ul>
                    </div>
                </div>

                <ul className="navbar-nav ml-auto">
                    <li className="nav-item header-toggler">
                        <a href="#" className="nav-link">
                            <i className="ti-arrow-down"></i>
                        </a>
                    </li>
                    <li className="nav-item sidebar-toggler">
                        <a href="#" className="nav-link">
                            <i className="ti-cloud"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

    )
}

export default Header
