import { 
    SET_LOADING_AUTH, AUTH_ERROR, LOGIN_SUCCESS, GET_AUTH_STATUS, USER_UPDATE_PASSWORD_RESET,
    GET_AUTH_STATUS_ERROR, LOGOUT_SUCCESS,PASSWORD_UPDATE_SUCCESS, PASSWORD_UPDATE_ERROR 
} from '../actions/types';
import { 
    PASSWORD_RESET_SENT, PASSWORD_RESET_SENT_ERROR, PASSWORD_RESET_LOADING, PASSWORD_RESET_INIT
} from '../constants/forgot';
import { 
    PASSWORD_RESET_CHANGE_SENT, PASSWORD_RESET_CHANGE_SENT_ERROR, PASSWORD_RESET_CHANGE_LOADING, PASSWORD_RESET_CHANGE_INIT
} from '../constants/resetPassword';

const initialState = {
    userInfo: null,
    loading : false,
    error : '',
    errorStatus:"",
    isAuth: null,
    successUpdatePasswd: false
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                userInfo : {
                    token: action.payload.token,
                    email:action.payload.user.email,
                    id_role:action.payload.user.id_role,
                    nom_prenom:action.payload.user.nom_prenom,
                    id_user:action.payload.user.id_user,
                    image:action.payload.user.image,
                    ref_role:action.payload.user.ref_role,
                    id_dossier_depot:action.payload.user.id_dossier_depot,
                    url_depot:action.payload.user.url_depot
                },
                loading: false,
                isAuth: true,
                error : ''
            };
        case LOGOUT_SUCCESS:
            return {
                userInfo: null,
                loading : false,
                error : '',
                isAuth: false
            };
        case SET_LOADING_AUTH:
            return {
                ...state,
                loading: true,
                successUpdatePasswd: false,
                error:''
            };
        case GET_AUTH_STATUS:
            return {
                ...state,
                loading: false,
                isAuth: action.payload.results && action.payload.results.email ? true: false,
                userInfo: action.payload.results ? action.payload.results: null,
                errorStatus:''
            };
        case PASSWORD_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                successUpdatePasswd: true,
            };
        case PASSWORD_UPDATE_ERROR:
            return {
                ...state,
                loading: false,
                successUpdatePasswd: false,
                error: action.payload
            };
        case USER_UPDATE_PASSWORD_RESET:
            return { 
                ...state, 
                successUpdatePasswd: false
            }
        case GET_AUTH_STATUS_ERROR:
            return {
                ...state,
                loading: false,
                isAuth: false,
                userInfo: null,
                errorStatus: action.payload
            };
            
        case AUTH_ERROR:
            return {
                ...state,
                userInfo: null,
                error: action.payload,
                loading: false,
                isAuth: false
            };
        default:
            return state;
    }
}

export const forgetReducer = (state = { success : false, error: null }, action) => {
    switch (action.type) {
        case PASSWORD_RESET_LOADING:
            return { loading: true }
        case PASSWORD_RESET_SENT:
            return { loading: false, success : action.payload.success  }
        case PASSWORD_RESET_SENT_ERROR:
            return { loading: false, success : action.payload.success , error: action.payload.error }
        case PASSWORD_RESET_INIT :
            return { success : false, error: null }
        default:
            return state
    }
}

export const resetPasswordReducer = (state = { success : false, error: null }, action) => {
    switch (action.type) {
        case PASSWORD_RESET_CHANGE_LOADING:
            return { loading: true }
        case PASSWORD_RESET_CHANGE_SENT:
            return { loading: false, success : action.payload.success  }
        case PASSWORD_RESET_CHANGE_SENT_ERROR:
            return { loading: false, success : action.payload.success , error: action.payload.error }
        case PASSWORD_RESET_CHANGE_INIT :
            return { success : false, error: null }
        default:
            return state
    }
}