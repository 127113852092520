import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetPassword } from '../store/actions/authAction';
import { NotificationManager } from 'react-notifications';
import Loader from '../components/Loader';
import Message from '../components/Message'
import {PASSWORD_RESET_CHANGE_INIT} from '../store/constants/resetPassword'

const Reset = ({ location, history }) => {
    
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const handleChangePassword = (e) => setPassword(e.target.value);
    const handleChangeConfirmPassword = (e) => setConfirmPassword(e.target.value);
    const dispatch = useDispatch();

    const resetPasswordReducer = useSelector((state) => state.resetPasswordReducer);
    const { success, loading, error } = resetPasswordReducer;
    
    useEffect(() => {
        if (success) {
            NotificationManager.success(`Votre mot de passe à été modifié avec succès.`, 'Succés', 5000);
            dispatch({type:PASSWORD_RESET_CHANGE_INIT})
            history.push("/")
        }
    }, [success])

    const handleOnSubmit = (e) => {
        e.preventDefault();
        const token = location.search.replace('?', '').split("=")[1];
        if(token.length === 0)
            return NotificationManager.error('Erreur token', 'Erreur', 5000);
        // login(user);
        if (password.length < 8 ) {
            return NotificationManager.error('Mot de passe trop court', 'Erreur', 5000);
        }
        if(password !== confirmPassword ) {
            return NotificationManager.error('Mot de passe non identique', 'Erreur', 5000);
        }
        
        dispatch(resetPassword(password, token)) 
    };
    return (
        <>
            <div className="form-membership" style={{background: 'url(media/image/image1.jpg)'}}>
                <div className="form-wrapper">

                    {/* <!-- logo --> */}
                    <div id="logo">
                        <img src="media/image/logo-dark.png" alt="logo" />
                    </div>
                    {/* <!-- ./ logo --> */}


                    <h5>Ré-initialiser votre mot de passe</h5>
                    <p>Vous avez demandé la réinitialisation de votre mot de passe</p>
                    {/* <!-- form --> */}
                    {error && <Message variant='danger'>{error || "Une erreur est survenue"}</Message> }
                    {loading && <Loader/> }

                    <form>
                        <div className="form-group">
                            <input type="password" className="form-control" placeholder="Nouveau mot de passe" required  onChange={handleChangePassword} />
                        </div>
                        <div className="form-group">
                            <input type="password" className="form-control" placeholder="Confirmer votre nouveau mot de passe" required  onChange={handleChangeConfirmPassword} />
                        </div>
                        <button className="btn btn-primary btn-block" onClick={handleOnSubmit} disabled={loading}>Changer le mot de passe</button>
                        
                    </form>
                    {/* <!-- ./ form --> */}

                </div>
            
            </div>
            
        </>
        )
}
export default Reset;
