import {
    ACCESS_BATCH_ADD_UPDATE_REQUEST, ACCESS_BATCH_ADD_UPDATE_SUCCESS, ACCESS_BATCH_ADD_UPDATE_FAIL,
    ACCESS_BATCH_ADD_UPDATE_RESET, ACCESS_USER_LIST_REQUEST, ACCESS_USER_LIST_SUCCESS ,ACCESS_USER_LIST_FAIL
} from '../actions/types'

export const accessBatchUpdatesReducer = (state = {}, action) => {
    switch (action.type) {
        case ACCESS_BATCH_ADD_UPDATE_REQUEST:
            return { loading: true }
        case ACCESS_BATCH_ADD_UPDATE_SUCCESS:
            return { loading: false, success: true }
        case ACCESS_BATCH_ADD_UPDATE_FAIL:
            return { loading: false, success: false, error: action.payload }
        case ACCESS_BATCH_ADD_UPDATE_RESET:
            return {}
        default:
            return state
    }
}

export const getAccessOfUserReducer = (state = { access: null }, action) => {
    switch (action.type) {
        case ACCESS_USER_LIST_REQUEST:
            return { loading: true }
        case ACCESS_USER_LIST_SUCCESS:
            return { loading: false, access: action.payload }
        case ACCESS_USER_LIST_FAIL:
            return { loading: false, access: null ,error: action.payload }
        default:
            return state
    }
}