import {
    DEPOTS_LIST_REQUEST, DEPOTS_LIST_SUCCESS, DEPOTS_LIST_FAIL,
    LAST_DEPOTS_LIST_REQUEST, LAST_DEPOTS_LIST_SUCCESS, LAST_DEPOTS_LIST_FAIL,
    DEPOTS_CONTENT_REQUEST,DEPOTS_CONTENT_SUCCESS,DEPOTS_CONTENT_FAIL, UPDATE_CURRENT_DEPOT
} from '../constants/depots'
import API from '../../utils/API';

//fetch dossiers depot (directly under root)
export const fetchDepotList = () => async(dispatch) => {
    try {
        dispatch({ type: DEPOTS_LIST_REQUEST })
        const {data} = await API({ method: 'get', url: `depots/user_depots` });
        dispatch({
            type: DEPOTS_LIST_SUCCESS,
            payload: data.depots
        })
    } catch (error) {
        const message =
            error.response && error.response.data ?
                error.response.data.error :
                error.message
        dispatch({
            type: DEPOTS_LIST_FAIL,
            payload: message
        });
    }
};

export const getDepotContent = (depotID) => async(dispatch) => {
    try {
        dispatch({ type: DEPOTS_CONTENT_REQUEST })
        const { data } = await API({ method: 'get', url: `/depots/depot_content/${depotID}` });
        dispatch({ 
            type: UPDATE_CURRENT_DEPOT, 
            payload : {
                id: data.structure.length>0 ? data.structure[0].id_dossier : depotID, 
                slug: data.structure.length>0 ? data.structure[0].slug : null
            } 
        })
        dispatch({
            type: DEPOTS_CONTENT_SUCCESS,
            payload: {
                depotContent: data.structure,
                access: data.access
            }
        })
    } catch (error) {
        const message =
            error.response && error.response.data ?
                error.response.data.error :
                error.message
        dispatch({
            type: DEPOTS_CONTENT_FAIL,
            payload: message
        });
    }
};

export const updateCurrentDepot = currentDepot => dispatch => {
    dispatch({ type: UPDATE_CURRENT_DEPOT, payload : currentDepot })        
};

//fetch dossiers depot (directly under root)
export const fetchLastDepotList = (limit, orderBy) => async(dispatch) => {
    try {
        dispatch({ type: LAST_DEPOTS_LIST_REQUEST })
        const {data} = await API({ method: 'get', url: `depots/user_depots?limit=${limit}&orderBy=${orderBy}` });
        dispatch({
            type: LAST_DEPOTS_LIST_SUCCESS,
            payload: data.depots
        })
    } catch (error) {
        const message =
            error.response && error.response.data ?
                error.response.data.error :
                error.message
        dispatch({
            type: LAST_DEPOTS_LIST_FAIL,
            payload: message
        });
    }
};
