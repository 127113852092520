import moment from 'moment';
//objectMap(myObject, v => 2*v)
export const objectMap = (obj, fn) =>
        Object.fromEntries(
            Object.entries(obj).map(
                ([k, v], i) => [k, fn(v, k, i)]
            )
        )

export const getUrlSiteByEnv = () => 
    `${process.env.NODE_ENV === 'development' ? 
        `${process.env.REACT_APP_URL_API_DEV}` : 
        `${process.env.REACT_APP_URL_API_PROD}`}`

export const dateFormatTexte = (dateInput) => {
    let ms = Date.parse(dateInput);
    let date = new Date(ms);
    return date.toLocaleString('fr-FR');
}

export const getIcon = (type, fileNameOrExtension) => {    
    if(type === 1) return {icon:'ti-folder', color: '#000000'};
    if(type === 3) return {icon:'ti-trash',color:'#d60027'};
    const fileNameOrExtensionLower = fileNameOrExtension.toLowerCase();
    const re = /(?:\.([^.]+))?$/;
    const extension = re.exec(fileNameOrExtensionLower)[1];
    if(type === 2 && extension && extension.length !== 0){
        switch (extension) {
            case "pdf":
                return {icon: 'far fa-file-pdf', color:'#d60027'};
            case "txt":
                return {icon:'fa fa-file', color: '#000000'};
            case "xlsx":
            case "xls":
                return {icon:'far fa-file-excel', color:'#43853d'};
            case "docx":
            case "doc":
                return {icon:'far fa-file-word', color:'#5599ff'};
            case "pptx":
            case "ppt":
                return {icon:'far fa-file-powerpoint', color:'#d50027'};
            case "jpeg":
            case "jpg":
            case "png":
            case "gif":
                return {icon:'far fa-file-image', color: '#000000'};
            case "csv":
                return {icon:"fas fa-file-csv", color:'#43853d'};
            default:
                return {icon:"ti-file", color: '#000000'};
            
        }
    }
    return {icon:"ti-file", color: '#000000'};
}

export function formatDate(date) {
    return (moment(date).utc().format('DD/MM/YYYY H:mm:ss'));
 }