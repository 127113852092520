import { GET_LOG_USER,LOG_USER_LIST_FAIL } from './types'
import API from '../../utils/API';


export const getLogUser = () => async(dispatch) => {
    try {
        const { data } = await API.get(`/logs_connexion`)

        dispatch({
            type: GET_LOG_USER,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: LOG_USER_LIST_FAIL,
            payload: error.message,
        })
    }
}