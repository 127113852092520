import {
    ACCESS_BATCH_ADD_UPDATE_REQUEST, ACCESS_BATCH_ADD_UPDATE_SUCCESS, ACCESS_BATCH_ADD_UPDATE_FAIL,
    ACCESS_USER_LIST_REQUEST, ACCESS_USER_LIST_SUCCESS ,ACCESS_USER_LIST_FAIL
} from './types'
import API from '../../utils/API';

export const batchAddUpdateAccess = (id_user, accessArray) => async(dispatch) => {
    try {

        dispatch({ type: ACCESS_BATCH_ADD_UPDATE_REQUEST })

        await API.post(`/acces/batchAddUpdate`, {id_user, dataArray: accessArray})

        dispatch({
            type: ACCESS_BATCH_ADD_UPDATE_SUCCESS
        })
    } catch (error) {
        const message =
            error.response && error.response.data ?
                error.response.data.error :
                error.message
        dispatch({
            type: ACCESS_BATCH_ADD_UPDATE_FAIL,
            payload: message,
        })
    }
}

export const getUserAccess = (id_user) => async(dispatch) => {
    try {
        dispatch({ type: ACCESS_USER_LIST_REQUEST })

        const { data } = await API.get(`/acces/filter?id_user=${id_user}`)

        dispatch({
            type: ACCESS_USER_LIST_SUCCESS,
            payload: data.access,
        })
    } catch (error) {
        const message =
            error.response && error.response.data ?
                error.response.data.error :
                error.message
        dispatch({
            type: ACCESS_USER_LIST_FAIL,
            payload: message,
        })
    }
}