import React from "react"; 
import { Form, Button } from 'react-bootstrap'
import  * as FileSaver from "file-saver"; 
const XLSX = require('sheetjs-style');


const ExportExcel = ({ excelData, fileName,wscols }) => {

    const fileType = 'application/vnd.openxmlformats-officedocument. Spreadsheetml.sheet; charset=UTF-8'; 
    const fileExtension = ".xlsx";

    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(excelData); 
        ws['!cols'] = wscols;
        const wb = { Sheets: { "data": ws }, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType }); 
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <>
            <div className="w-100 d-flex justify-content-end" style={{marginBottom: '-34px'}}>
                <Button variant='primary' onClick={(e)=> exportToExcel(fileName)}>
                     Exporter excel
                </Button>
            </div>
        </>
    )
}

export default ExportExcel;
 


