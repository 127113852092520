import { useState,useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLogActionUser } from '../../store/actions/historiquesUserAction';
import {formatDate} from '../../utils/function';
import ExportExcel  from '../excelexport';
import Layout from '../Layout';
import DataTable from '../../components/DataTable'
import { Link } from 'react-router-dom';
import API from '../../utils/API';
import { NotificationManager } from 'react-notifications';
import { Modal, Button, Form } from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert'; 
import { fetchDepotList, fetchLastDepotList } from '../../store/actions/depotActions';
import { dateFormatTexte } from '../../utils/function';

const LogsListScreen = () => {

    const dispatch = useDispatch();

    const {listeAction} = useSelector((state) => state.userActionLogs);

    const actions = [{action:'Consultation',badge:'badge-info',message: 'a été consultée depuis'},
                     {action:'Téléchargement',badge:'badge-success',message: 'a été téléchargée depuis'},
                     {action:'Copiée',badge:'badge-secondary',message: 'a été copiée depuis'},
                     {action:'Déplacement',badge:'badge-primary',message: 'a été Déplacée depuis'},
                     {action:'Renommée',badge:'badge-warning',message: 'a été renommée depuis'},
                     {action:'Suppression',badge:'badge-danger',message: 'a été supprimée depuis'},
                    ];

    const colsArray = [
        {
            label: 'ID',
            field: 'id',
        },
        {
            label: 'Nom & Prénom',
            field: 'nom_user',
        },
        {
            label: 'Nom de dossier ou fichier',
            field: 'nom',
        },
        {
            label: 'Message',
            field: 'messageData',
        },
        {
            label: 'Type d\'Action',
            field: 'type_action',
        },
        {
            label: 'Date',
            field: 'date',
        },
    ]

    useEffect(() => {
        dispatch(getLogActionUser())
    }, [dispatch])

    const ExcelExportData = () => {
        let rowsArray = [];
        listeAction.map((item) => {
            const fileRenomme = item.type_action == 5 ?item.nom_change:'';
            const fileDestine = item.type_action == 3 || item.type_action == 4 ?item.destination:'';
            const row = {
                'Id': item.id,
                "Nom & Prénom": item.nom_user,
                "Fichier": item.nom,
                "Message": getMessage(item.type_file,item.nom_user,item.ip_adresse,item.type_action,item.destination,item.nom_change),
                "Message": item.type_file+' '+actions[item.type_action - 1].message+' '+item.ip_adresse+'\n'+fileRenomme+'\n'+fileDestine,
                "Type d'action": actions[item.type_action - 1].action,
                "Date": formatDate(item.date),
            }
            
            rowsArray.push(row)
            return null;
        });

        return rowsArray;
    }
    const wscols = [ {wch:10}, {wch:20}, {wch:30}, {wch:50},{wch:30},{wch:30}];

    const getRowsArray = () => {
        let rowsArray = [];
        listeAction.map((item) => {
            const row = {
                id: item.id,
                nom_user: item.nom_user,
                nom: item.nom,
                messageData: getMessage(item.type_file,item.nom_user,item.ip_adresse,item.type_action,item.destination,item.nom_change),
                type_action: getTypeAction(item.type_action),
                date: formatDate(item.date),
            }
            
            rowsArray.push(row)
            return null;
        });

        return rowsArray;
    }

    const getTypeAction = (type_action) => (
        <span class={`badge fs-15 ${actions[type_action - 1].badge}`}>{actions[type_action - 1].action}</span>
    )

    const getMessage = (type_file,user,ip_adresse,type_action,destination,nom_change) => (
        <>
             <span>{type_file+' '+actions[type_action - 1].message}</span> <br/>
             <span>{ip_adresse}</span> <br/>
             {type_action == 5 && <span>{nom_change}</span>}
             {(type_action == 3 || type_action == 4) && <span>Vers :({destination})</span>}
        </>
    )
        

    return (
        <Layout>
            <div className="page-header d-flex justify-content-between">
                <div className="content-title" style={{ width: "100%" }}>
                    <h4>Historique des actions</h4>
                </div>
            </div>

            <ExportExcel excelData={ExcelExportData()} fileName={"logs-action-user"} wscols={wscols} />
            {listeAction && listeAction.length> 0 ?
                <DataTable
                    rows = {getRowsArray()}
                    columns = {colsArray}
                    pagesRange = {[20, 30, 50, 100]}
                /> :
                <DataTable
                    columns = {colsArray}
                    pagesRange = {[20, 30, 50, 100]}
                />}
        </Layout>
    )
}


export default LogsListScreen;