import { 
    SET_LOADING_AUTH, AUTH_ERROR, LOGIN_SUCCESS, GET_AUTH_STATUS, 
    GET_AUTH_STATUS_ERROR, LOGOUT_SUCCESS,
    PASSWORD_UPDATE_SUCCESS, PASSWORD_UPDATE_ERROR
} from './types';
import { 
    PASSWORD_RESET_SENT, PASSWORD_RESET_SENT_ERROR, PASSWORD_RESET_LOADING
} from '../constants/forgot';
import { 
    PASSWORD_RESET_CHANGE_SENT, PASSWORD_RESET_CHANGE_SENT_ERROR, PASSWORD_RESET_CHANGE_LOADING
} from '../constants/resetPassword';

import API from '../../utils/API';
import axios from "axios";

export const login = (email, password) => async (dispatch) => {
    try {
        dispatch(setLoading());
           
        //const ip_adresse = await axios.get('https://geolocation-db.com/json/');
        //ip_adresse = ip_adresse.data.IPv4;

        let ip_adresse = '127.0.0.1';

        try {
            const ipify = await axios.get('https://api.ipify.org/?format=json');
            ip_adresse = ipify.data.ip || ip_adresse;
        } catch (error) {
            console.error('Failed to get IP address, using default', error);
        }

        // const api_get_ip = await API.get("/users/ip-adresse");
        // if (api_get_ip.status == 200) {
        //    ip_adresse = api_get_ip.data.ip;
        // }

        const loginRequest= await API.post("/auth/login", {email, passwd:password,ipadresse:ip_adresse});
        dispatch({
            type: LOGIN_SUCCESS,
            payload: loginRequest.data
        });
    } catch (error) {
        dispatch({
            type: AUTH_ERROR,
            payload: error.response && error.response.data
                        ? error.response.data.error : error.message
        });
    }
};


export const isAuthenticated = () => async (dispatch) => {
    try {
        dispatch(setLoading());
        const isAuthRequest = await API.get("/auth/me");
        
        dispatch({
            type: GET_AUTH_STATUS,
            payload: isAuthRequest.data
        });
    } catch (error) {
        dispatch({
            type: GET_AUTH_STATUS_ERROR,
            payload: error.response && error.response.data
                ? error.response.data.error : error.message
        });
    }
};
export const logout = () => async (dispatch) => {
    try {
        dispatch(setLoading());
        await API.get("/auth/logout");
        dispatch({
            type: LOGOUT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: GET_AUTH_STATUS_ERROR,
            payload:  error.message
        });
    }
};

// Set loading to true
export const setLoading = () => {
    return {
        type: SET_LOADING_AUTH
    };
    
};

export const updateUserPasswd = (isAdmin, id, currentPassword, newPassword) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LOADING_AUTH
        })

    if (isAdmin) {
        id === 0 ? //if 0 then update admin passwd else update a user passwd
            await API.put(`/auth/updatepassword`, {id_user: 0, currentPassword, newPassword}) :
            await API.put(`/auth/updatepassword`, {id_user: id, newPassword}) 
    } else {//a user updates its passwd
        await API.put(`/auth/updatepassword`, {currentPassword, newPassword}) 
    }

    dispatch({
        type: PASSWORD_UPDATE_SUCCESS
    })
    } catch (error) {
        const message =
            error.response && error.response.data.error
                ? error.response.data.error
                : error.message
        dispatch({
            type: PASSWORD_UPDATE_ERROR,
            payload: message,
        })
    }
}

export const forgetPassword = (email) => async (dispatch) => {
    try {
        dispatch({type: PASSWORD_RESET_LOADING});
        const loginRequest= await API.post("/auth/forget", {email});
        dispatch({
            type: PASSWORD_RESET_SENT,
            payload:  {
                error: null,
                success:loginRequest.data.success
            }
        });
    } catch (error) {
        dispatch({
            type: PASSWORD_RESET_SENT_ERROR,
            payload: {
                error: error.response && error.response.data ? error.response.data.error : error.message,
                success:false
            }
        });
    }
};

export const resetPassword = (password, token) => async (dispatch) => {
    try {
        dispatch({type: PASSWORD_RESET_CHANGE_LOADING});
        const loginRequest= await API.put("/auth/resetpassword?token="+token, {password});
        dispatch({
            type: PASSWORD_RESET_CHANGE_SENT,
            payload:  {
                error: null,
                success:loginRequest.data.success
            }
        });
    } catch (error) {
        dispatch({
            type: PASSWORD_RESET_CHANGE_SENT_ERROR,
            payload: {
                error: error.response && error.response.data ? error.response.data.error : error.message,
                success:false
            }
        });
    }
};