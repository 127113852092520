import axios from "axios";

const API= axios.create({
    baseURL: `${process.env.NODE_ENV === 'development' ? 
        `${process.env.REACT_APP_URL_API_DEV}/api/v1` : 
        `${process.env.REACT_APP_URL_API_PROD}/api/v1`}`,
    responseType: "json",
    withCredentials: true
});

//used for refreshing the expired token. 
API.interceptors.response.use((response) => {
    return response
}, async function (error) {
        const originalRequest = error.config;
        if (error.response.status === 409 && !originalRequest._retry) {
            originalRequest._retry = true;
            await API.get("/refreshToken"); 
            //axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
            return API(originalRequest);
        }
        return Promise.reject(error);
    });

export default API;
