import { combineReducers } from 'redux';
import 
    listDocumentReducer, 
    {
        getActiveParentFoldersReducer, filesTreeReducer, fileInfosReducer, fileStatistiquesReducer
    } 
from './listDocumentReducer';
import {authReducer, forgetReducer, resetPasswordReducer} from './authReducer';
import {
    userListReducer, userDetailsReducer, userCreationReducer, userUpdateReducer, userDeleteReducer,
} from './userReducer';
import { roleReducer, roleDetailsReducer, roleCreationReducer, roleUpdateReducer, roleDeleteReducer } from './roleReducers';
import { depotReducer, depotContentReducer, lastDepotReducer } from './depotReducers';
import {accessBatchUpdatesReducer, getAccessOfUserReducer} from './accessReducers';
import { userLogsReducer } from './historiquesConnexionReducers';
import { logsActionUserReducer } from './historiquesUserAction';
import { LOGOUT_SUCCESS} from '../actions/types';
import { depotModalStatusReducer } from './utils';

const appReducer= combineReducers({
    auth : authReducer,
    userListReducer,
    userDetails: userDetailsReducer,
    userCreate :userCreationReducer,
    userUpdate: userUpdateReducer,
    userDelete: userDeleteReducer,
    userLogs : userLogsReducer,
    userActionLogs: logsActionUserReducer,
    listDocument: listDocumentReducer,
    roles: roleReducer,
    roleDetails: roleDetailsReducer,
    roleCreate :roleCreationReducer,
    roleUpdate: roleUpdateReducer,
    roleDelete: roleDeleteReducer,
    depotsList: depotReducer,
    lastDepotsList: lastDepotReducer,
    depotContent: depotContentReducer,
    filesTree: filesTreeReducer,
    fileInfos: fileInfosReducer,
    activeParentFolder: getActiveParentFoldersReducer,
    accessUpdates: accessBatchUpdatesReducer,
    accessUser: getAccessOfUserReducer,
    forgetReducer : forgetReducer,
    resetPasswordReducer : resetPasswordReducer,
    statsReducer: fileStatistiquesReducer,
    depotModalStatus : depotModalStatusReducer
});

const rootReducer = (state, action) => {   
    // Clear all data in redux store to initial.
    if(action.type === LOGOUT_SUCCESS)
        state = undefined;
    
    return appReducer(state, action);
};

export default rootReducer;