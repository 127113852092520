import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap'

const Page404 = () => {
    return (
        <div className="error-page bg-white" style={{background: 'url(../../assets/media/image/image1.jpg)'}}>

            <div>
                <h4 className="mb-0 font-weight-normal">Upps! Page introuvable!</h4>
            </div>

            <div className="my-4">
                <span className="error-page-item font-weight-bold">4</span>
                <span className="error-page-item font-weight-bold">0</span>
                <span className="error-page-item font-weight-bold">4</span>
            </div>

            <div>
                <Link to="/" className="btn btn-xl">
                    <Button variant="outline-light" style={{fontSize: '22px',padding: '19px'}}>Retour à l'accueil</Button>
                </Link>
            </div>
        </div>
    )
}

export default Page404
