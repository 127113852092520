import React, {Component } from 'react'
import { connect } from 'react-redux';
import $ from 'jquery'
import fileDownload from 'js-file-download';
import 'react-notifications/lib/notifications.css';
import {fetchFilesTree, updateActivePathname, updateActiveFileInfos } from '../store/actions/listDocumentAction';
import { getDepotContent } from '../store/actions/depotActions';
import { saveLogActionUser } from '../store/actions/historiquesUserAction';
import ArboLeft from '../components/ArboLeft';
import { Modal, Button, Form, DropdownButton, ButtonGroup, Dropdown } from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { NotificationManager } from 'react-notifications';
import Layout from './Layout';
import API from '../utils/API';
import Loader from '../components/Loader'
import FadeLoader from '../components/FadeLoader'
import Message from '../components/Message'
import {dateFormatTexte, getIcon} from '../utils/function';
import { setModalStatus } from '../store/actions/utils';

class Homepage  extends Component {

    state= {
        isUploadingFile : false,
        showModalAddFolder:false,
        showModalRenameFolder:false,
        showModalCopyFolder : false,
        showModalMoveFolder : false,
        newFolderName: '',
        OldFolderName:'',//for rename
        NewFolderPath:'',//for copy & move
        OldFolderNPath:'',
        idFolderToEdit : null,//current edited doc
        selectedFolderID: null,
        selectedFolderName:'',
        fatherOfSelectedFolder:null,//to refresh content_list after actions
        operationEnCours : false,//to disable submit btns in modals
        loadingDelete: false,
        isInRecycleBin: false,
        isDownloading: false,
        isActifBtnCopyOrMove: false,
        selectedToBatchDelete: [],
        selectedToBatchDeletePermanently: [],
        showBtnGroupedActions : false
    }
    componentDidMount() {
        const {location, currentDepot, getDepotContent, authenticatedUser, updateActiveFileInfos, setModalStatus } = this.props
        const submittedDepot = location.search && location.search.split('depot=')[1].split("&")[0];
        if (submittedDepot && Number(submittedDepot) !== currentDepot?.id) {//si on passe id_dossier en query different du depot du store
            getDepotContent(submittedDepot)
            updateActiveFileInfos(submittedDepot)
        }else if(currentDepot) {//si on a un depot dans store,chercher son contenu
            getDepotContent(currentDepot.id)
            updateActiveFileInfos(currentDepot.id)
        }else{//get depot by defaut of user
            setModalStatus(true);
            getDepotContent(authenticatedUser.id_dossier_depot)
            updateActiveFileInfos(authenticatedUser.id_dossier_depot)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            selectedPath, fetchFilesTree, currentDepot, depotContent,location, getDepotContent,listDepots,
            loadingDepotContent, updateActiveFileInfos, errorDepotContent,loadingFiles,loadingPathname, updateActivePathname
        } = this.props;
        const submittedDepot = location.search && location.search.split('depot=')[1].split("&")[0];
        
        if (submittedDepot && currentDepot && Number(submittedDepot) !== Number(currentDepot?.id) && !loadingDepotContent && !errorDepotContent) {
            //user navigates in the depots when he's in /home
            getDepotContent(submittedDepot)
            updateActiveFileInfos(submittedDepot)
        }
        if (!prevProps.depotContent && !depotContent && !loadingDepotContent && listDepots?.length> 0 ) {
            // if no depot found, check the list of depots of that user and grap the first one(if nothing found, jsx will show err)
            getDepotContent(listDepots[0].id_dossier)
            updateActiveFileInfos(listDepots[0].id_dossier)
        }
        
        if ((!prevProps.currentDepot && currentDepot && !loadingFiles && !loadingPathname) || (currentDepot && prevProps.currentDepot && prevProps.currentDepot.id !== currentDepot.id)){
                //currentDepot is changed
                updateActivePathname(currentDepot.id) 
                fetchFilesTree(currentDepot.id)
                updateActiveFileInfos(currentDepot.id)
        }

        if ((!prevProps.selectedPath && selectedPath) || (prevProps.selectedPath !== selectedPath)) {//when current pathname change:click on table or jstree element
            selectedPath !== '' && fetchFilesTree(selectedPath)
            this.setState({selectedToBatchDeletePermanently: [], selectedToBatchDelete : []})
        }
        if (prevState.showModalCopyFolder !== this.state.showModalCopyFolder) {//showModalCopyFolder changed
            const {updateNewFolderPath, updateIsActifBtnCopyOrMove} = this;
            if (this.state.showModalCopyFolder) {//generate jstree
                $('#filesCopyModal')
                    .on('ready.jstree', function (e, data) {
                        const dataElement = data.instance._model.data;
                        for (const elem in dataElement) {
                            if (elem !== '#') {
                                //hide corbeille
                                dataElement[elem].original.type ===3 && 
                                    $("#filesCopyModal").jstree(true).hide_node( dataElement[elem])
                            }
                        }
                    })
                    .on('changed.jstree', function (e, data) {
                        data.node && data.node.original && updateNewFolderPath(data.node.original.id)
                        updateIsActifBtnCopyOrMove(true)
                    })
                .jstree({
                    'core':  {
                        'data': depotContent,
                        themes: {
                            dots: false,
                        },
                        check_callback  : true,
                    }
                });
            } else {//destroy jstree
                $("#filesCopyModal").jstree("destroy");
                this.setState({NewFolderPath: ''})
            }
        }
        if (prevState.showModalMoveFolder !== this.state.showModalMoveFolder) {//showModalMoveFolder changed
            const {updateNewFolderPath, updateIsActifBtnCopyOrMove} = this;
            if (this.state.showModalMoveFolder) {//generate jstree
                $('#filesMoveModal')
                    .on('ready.jstree', function (e, data) {
                        const dataElement = data.instance._model.data;
                        for (const elem in dataElement) {
                            if (elem !== '#') {
                                //hide corbeille
                                dataElement[elem].original.type ===3 && 
                                    $("#filesMoveModal").jstree(true).hide_node( dataElement[elem])
                            }
                        }
                    })
                    .on('changed.jstree', function (e, data) {
                        data.node && data.node.original && updateNewFolderPath(data.node.original.id)
                        updateIsActifBtnCopyOrMove(true)
                    })
                .jstree({
                    'core':  {
                        'data': depotContent,
                        themes: {
                            dots: false,
                        },
                        check_callback  : true,
                    }
                });
            } else {//destroy jstree
                $("#filesMoveModal").jstree("destroy");
                this.setState({NewFolderPath: ''})
            }
        }
    }
    
    uploadFileHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', file);
        formData.append('actif', 1);
        formData.append('type', 2);
        formData.append('is_archive', 0);
        formData.append('id_parent', this.props.selectedPath);
        formData.append('nom', e.target.files[0].name);
        formData.append('id_user_creation', this.props.authenticatedUser.id_user);
        this.setState({isUploadingFile:true})

        try {
            const response = await API.post(`/dossiers/fileupload`, formData)
            const { data:{success }} = response;
            if (success) {
                this.setState({isUploadingFile:false})
                NotificationManager.success('Le fichier a été sauvegardé dans le systéme', 'Succés', 5000);
                this.props.updateActivePathname(this.props.selectedPath)
                // this.props.getDepotContent(this.props.currentDepot.id)
                this.props.fetchFilesTree(this.props.selectedPath|| this.props.currentDepot.id)
            }
        } catch (err) {
            console.error(err.message)
            this.setState({ 
                isUploadingFile:false
            })
            const errorMsg = err.response && err.response.data ? err.response.data.error : err.message;
            NotificationManager.error("Une erreur d'upload est survenue : "+errorMsg, 'Erreur', 5000);
        }
    }
    addFolderHandler = async () => {
        const {newFolderName} = this.state;
        const data = { 
            //pathname: `files/${this.props.selectedPath}`,
            nom:newFolderName,
            id_parent:this.props.selectedPath ||this.props.currentDepot.id,
            is_archive:0,	
            id_user_creation:this.props.authenticatedUser.id_user,
            actif:1,
            type:1
        }
        this.setState({isUploadingFile:true})
        try {
            const response = await API.post(`/dossiers`, data)
            const { data:{success }} = response;
            if (success) {
                NotificationManager.success(`Le dossier ${newFolderName} a été crée`, 'Succés', 5000);
            }           
            this.props.getDepotContent(this.props.currentDepot.id)
            this.props.updateActivePathname(this.props.selectedPath || this.props.currentDepot.id)//active pathname is the father of current folder
            this.props.updateActiveFileInfos(this.props.selectedPath || this.props.currentDepot.id)//active pathname is the father of current folder
            this.props.fetchFilesTree(this.props.selectedPath || this.props.currentDepot.id)
            this.setState({newFolderName:'', showModalAddFolder:false, isUploadingFile:false})  
        } catch (err) {
            console.error(err.message)
            this.setState({newFolderName:'', showModalAddFolder:false, isUploadingFile:false})
            const errorMsg = err.response && err.response.data ? err.response.data.error : err.message;
            NotificationManager.error("Une erreur est survenue : "+errorMsg, 'Erreur', 5000);
        }
    }
    renameFolderHandler = async () => {
        const {newFolderName, idFolderToEdit, selectedFolderName, fatherOfSelectedFolder} = this.state;
        const {getDepotContent, updateActivePathname, currentDepot, fetchFilesTree, updateActiveFileInfos}= this.props;
        const data = { 
            id: idFolderToEdit,
            oldName: selectedFolderName,
            nom :newFolderName
        }
        this.setState({operationEnCours: true})
        try {
            const response = await API.put(`/dossiers/actions?action=rename`, data)
            const { data:{success }} = response;
            if (success) {
                NotificationManager.success(`Le dossier ${selectedFolderName} a été renommé`, 'Succés', 5000);
            }

            this.props.saveLogActionUser(idFolderToEdit,'5',null,newFolderName);
            getDepotContent(currentDepot.id)
            updateActivePathname(fatherOfSelectedFolder ? fatherOfSelectedFolder : idFolderToEdit)//active pathname is the father of current folder
            updateActiveFileInfos(fatherOfSelectedFolder ? fatherOfSelectedFolder : idFolderToEdit)//active pathname is the father of current folder
            fetchFilesTree(fatherOfSelectedFolder !== 0 ? fatherOfSelectedFolder : idFolderToEdit)
            this.setState({newFolderName: '', OldFolderName: '', showModalRenameFolder: false, operationEnCours: false, fatherOfSelectedFolder:null})
        } catch (err) {
            console.error(err.message)
            this.setState({newFolderName:'',OldFolderName:'', showModalRenameFolder:false, operationEnCours: false,  fatherOfSelectedFolder:null})
            const errorMsg = err.response && err.response.data ? err.response.data.error : err.message;
            NotificationManager.error("Une erreur est survenue : "+errorMsg, 'Erreur', 5000);
        } 
    }
    copyFolderHandler = async () => {
        const {NewFolderPath,idFolderToEdit, fatherOfSelectedFolder} = this.state;
        const data = { 
            id:idFolderToEdit,
            destination : NewFolderPath,
        }

        this.setState({operationEnCours: true})
        try {
            const response = await API.put(`/dossiers/actions?action=copy`, data)
            const { data:{success }} = response;
            if (success) {
                NotificationManager.success(`Operation effectué`, 'Succés', 5000);
            }

            this.props.saveLogActionUser(idFolderToEdit,'3',NewFolderPath);
            this.props.getDepotContent(this.props.currentDepot.id)
            this.props.updateActivePathname(fatherOfSelectedFolder ? fatherOfSelectedFolder : idFolderToEdit)//active pathname is the father of current folder
            this.props.updateActiveFileInfos(fatherOfSelectedFolder ? fatherOfSelectedFolder : idFolderToEdit)//active pathname is the father of current folder
            this.props.fetchFilesTree(fatherOfSelectedFolder !== 0 ? fatherOfSelectedFolder : idFolderToEdit)

            this.setState({NewFolderPath:'',showModalCopyFolder:false, operationEnCours: false, idFolderToEdit: null})
        } catch (err) {
            console.error(err.message)
            this.setState({NewFolderPath:'', showModalCopyFolder:false, operationEnCours: false, idFolderToEdit: null})
            const errorMsg = err.response && err.response.data ? err.response.data.error : err.message;
            NotificationManager.error("Une erreur est survenue : "+errorMsg, 'Erreur', 5000);
        } 
    }
    moveFolderHandler = async () => {
        const {NewFolderPath,idFolderToEdit, fatherOfSelectedFolder} = this.state;
        const data = { 
            id:idFolderToEdit,
            destination : NewFolderPath,
        }
        this.setState({operationEnCours: true})
        try {
            const response = await API.put(`/dossiers/actions?action=move`, data)
            const { data:{success }} = response;
            if (success) {
                NotificationManager.success(`Operation effectué`, 'Succés', 5000);
            }

            this.props.saveLogActionUser(idFolderToEdit,'4',NewFolderPath);
            this.props.getDepotContent(this.props.currentDepot.id)
            this.props.updateActivePathname(fatherOfSelectedFolder ? fatherOfSelectedFolder : idFolderToEdit)//active pathname is the father of current folder
            this.props.updateActiveFileInfos(fatherOfSelectedFolder ? fatherOfSelectedFolder : idFolderToEdit)//active pathname is the father of current folder
            this.props.fetchFilesTree(fatherOfSelectedFolder !== 0 ? fatherOfSelectedFolder : idFolderToEdit)

            this.setState({NewFolderPath:'',showModalMoveFolder:false, operationEnCours: false, idFolderToEdit: null,  fatherOfSelectedFolder:null})
        } catch (err) {
            console.error(err.message)
            this.setState({NewFolderPath:'', showModalMoveFolder:false, operationEnCours: false, idFolderToEdit: null,  fatherOfSelectedFolder:null})
            const errorMsg = err.response && err.response.data ? err.response.data.error : err.message;
            NotificationManager.error("Une erreur est survenue : "+errorMsg, 'Erreur', 5000);
        } 
    }
    
    deleteDocumentHandler = (e,  id, id_parent) => {
        e.preventDefault()
        confirmAlert({
            title: 'Confirmation',
            message: 'Etes-vous sûr de vouloir supprimer ce document?',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () =>  {this.deleteDocument( id, id_parent);this.props.saveLogActionUser(id,'6');}
                },
                {
                    label: 'Non',
                    onClick: () => null
                }
            ]
        });
    }
    deletePermanentDocumentHandler = (e,  id, id_parent) => {
        e.preventDefault()
        confirmAlert({
            title: 'Confirmation',
            message: 'Etes-vous sûr de vouloir supprimer ce document définitivement?',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () =>  this.deletePermanently( id, id_parent)
                },
                {
                    label: 'Non',
                    onClick: () => null
                }
            ]
        });
    }

    deleteBatchDocumentHandler = (e) => {
        e.preventDefault()
        confirmAlert({
            title: 'Confirmation',
            message: 'Etes-vous sûr de vouloir supprimer les documents sélectionnés ?',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () =>  this.deleteBatchDocument()
                },
                {
                    label: 'Non',
                    onClick: () => null
                }
            ]
        });
    }

    batchDeletePermanentHandler = (e) => {
        e.preventDefault()
        confirmAlert({
            title: 'Confirmation',
            message: 'Etes-vous sûr de vouloir supprimer ces documents définitivement?',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () =>  this.batchDeletePermanent()
                },
                {
                    label: 'Non',
                    onClick: () => null
                }
            ]
        });
    }
    
    deleteDocument = async (id, id_parent) => {
        this.setState({loadingDelete: true})
        try {
            await API.put(`/dossiers/actions?action=delete`, { id})
            NotificationManager.success(`Le dossier a été supprimé`, 'Succés', 5000);
            this.setState({loadingDelete: false, selectedToBatchDeletePermanently: [], selectedToBatchDelete : []})
            this.props.getDepotContent(this.props.currentDepot.id)
            this.props.updateActivePathname(id_parent !== 0 ? id_parent : id)//active pathname is the father of current folder
            this.props.updateActiveFileInfos(id_parent !== 0 ? id_parent : id)//active pathname is the father of current folder
            this.props.fetchFilesTree(id_parent !== 0 ? id_parent : id)

        } catch (err) {
            console.error(err.message)
            const errorMsg = err.response && err.response.data ? err.response.data.error : err.message;
            NotificationManager.error("Une erreur est survenue : "+errorMsg, 'Erreur', 5000);
            this.setState({loadingDelete: false})
        } 
    }
    deletePermanently = async (id, id_parent) => {
        this.setState({loadingDelete: true, selectedToBatchDeletePermanently: [], selectedToBatchDelete : []})
        try {
            await API.put(`/dossiers/actions?action=deletePermanent`, { id})
            NotificationManager.success(`Le dossier a été supprimé de la corbeille`, 'Succés', 5000);
            this.setState({loadingDelete: false})

            this.props.getDepotContent(this.props.currentDepot.id)
            this.props.updateActivePathname(id_parent !== 0 ? id_parent : id)//active pathname is the father of current folder
            this.props.updateActiveFileInfos(id_parent !== 0 ? id_parent : id)//active pathname is the father of current folder
            this.props.fetchFilesTree(id_parent !== 0 ? id_parent : id)

        } catch (err) {
            console.error(err.message)
            const errorMsg = err.response && err.response.data ? err.response.data.error : err.message;
            NotificationManager.error("Une erreur est survenue : "+errorMsg, 'Erreur', 5000);
            this.setState({loadingDelete: false})
        } 
    }

    deleteBatchDocument = async() => {
        this.setState({loadingDelete: true})
        // map every url to the promise of the fetch
        let requests = this.state.selectedToBatchDelete.map(folderID => API.put(`/dossiers/actions?action=delete`, {id: folderID}));

        // Promise.all waits until all jobs are resolved
        Promise.all(requests)
            .then(responses => {
                NotificationManager.success(`Suppression de(s) dossier(s) éffectuée avec succés`, 'Succés', 5000);
                this.setState({loadingDelete: false, selectedToBatchDelete : [], showBtnGroupedActions:false});
                this.props.getDepotContent(this.props.currentDepot.id);
                this.props.fetchFilesTree(this.props.selectedPath);
            })
            .catch(err => {
                console.error(err.message)
                const errorMsg = err.response && err.response.data ? err.response.data.error : err.message;
                NotificationManager.error("Une erreur est survenue : "+errorMsg, 'Erreur', 5000);
            });
        // await this.state.selectedToBatchDelete.forEach(async folderID => {
        //     try {
        //         await API.put(`/dossiers/actions?action=delete`, {id: folderID})
        //     } catch (err) {
        //         console.error(err.message)
        //         const errorMsg = err.response && err.response.data ? err.response.data.error : err.message;
        //         NotificationManager.error("Une erreur est survenue : "+errorMsg, 'Erreur', 5000);
        //     } 
        // });
        // NotificationManager.success(`Suppression de(s) dossier(s) éffectuée avec succées`, 'Succés', 5000);
        // this.setState({loadingDelete: false, selectedToBatchDelete : [], showBtnGroupedActions:false});
        // // this.props.updateActivePathname(this.props.selectedPath)//active pathname is the father of current folder
        // // this.props.updateActiveFileInfos(this.props.selectedPath)//active pathname is the father of current folder
        // this.props.getDepotContent(this.props.currentDepot.id);
        // this.props.fetchFilesTree(this.props.selectedPath);
    }

    batchDeletePermanent = async() => {
        this.setState({loadingDelete: true})
        // map every url to the promise of the fetch
        let requests = this.state.selectedToBatchDeletePermanently.map(folderID => API.put(`/dossiers/actions?action=deletePermanent`, {id: folderID}));

        // Promise.all waits until all jobs are resolved
        Promise.all(requests)
            .then(responses => {
                NotificationManager.success(`Suppression de(s) dossier(s) éffectuée avec succés`, 'Succés', 5000);
                this.setState({loadingDelete: false, selectedToBatchDeletePermanently: [], selectedToBatchDelete : [], showBtnGroupedActions:false});
                this.props.getDepotContent(this.props.currentDepot.id);
                this.props.fetchFilesTree(this.props.selectedPath);
            })
            .catch(err => {
                console.error(err.message)
                const errorMsg = err.response && err.response.data ? err.response.data.error : err.message;
                NotificationManager.error("Erreur Suppression par lot: "+errorMsg, 'Erreur', 5000);
            });
    }
    
    restoreDocument = async (e, id, id_parent) => {
        e.preventDefault()
        this.setState({operationEnCours: true})
        try {
            await API.put(`/dossiers/actions?action=restore`, {id})
            NotificationManager.success(`Le document a été restauré`, 'Succés', 5000);
            this.setState({operationEnCours: false})

            this.props.getDepotContent(this.props.currentDepot.id)
            this.props.updateActivePathname(id_parent !== 0 ? id_parent : id)//active pathname is the father of current folder
            this.props.updateActiveFileInfos(id_parent !== 0 ? id_parent : id)//active pathname is the father of current folder
            this.props.fetchFilesTree(id_parent !== 0 ? id_parent : id)

        } catch (err) {
            console.error(err.message)
            const errorMsg = err.response && err.response.data ? err.response.data.error : err.message;
            NotificationManager.error("Une erreur est survenue : "+errorMsg, 'Erreur', 5000);
            this.setState({operationEnCours: false})
        } 
    }
    downloadFileHandler = async(e, id, slug) => {
        e.preventDefault()
        try {
            this.setState({isDownloading: true})
            const configHeaders = {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                }
            }
            const res = await API.put(`/dossiers/actions?action=download`, {id}, configHeaders)
            fileDownload(res.data, slug); //This will download the file in browser.
            this.setState({isDownloading: false})
        } catch (err) {
            console.error(err.message)
            this.setState({isDownloading: false})
            const errorMsg = err.response && err.response.data ? err.response.data.error : err.message;
            NotificationManager.error("Une erreur est survenue : "+errorMsg, 'Erreur', 5000);
        } 
    }
    changePathname = (e, file) => {
        e.preventDefault()
        
        if(file.is_archive === 1 || file.type === 3)
            this.setState({isInRecycleBin : true})
        else
            this.setState({isInRecycleBin : false})

        this.props.updateActivePathname(`${file.id_dossier}`)
        this.props.updateActiveFileInfos(`${file.id_dossier}`)
        this.setState({selectedToBatchDelete : [], showBtnGroupedActions:false})
    }
    goToParent = (e, file) => {
        e.preventDefault()

        if(file.is_archive === 1 || file.type === 3)
            this.setState({isInRecycleBin : true})
        else
            this.setState({isInRecycleBin : false})

        this.props.updateActivePathname(`${file.id_parent}`)
        this.props.updateActiveFileInfos(`${file.id_parent}`)
        this.setState({selectedToBatchDelete : [], showBtnGroupedActions:false})
    }
    changeFileInfos = (e, dossierID) => {
        e.preventDefault()
        this.props.updateActiveFileInfos(`${dossierID}`)
    }
    
    updateNewFolderPath = (newValue) => this.setState({NewFolderPath: newValue})
    updateIsInRecycle = (newValue) => this.setState({isInRecycleBin: newValue})
    updateIsActifBtnCopyOrMove = (newValue) => this.setState({isActifBtnCopyOrMove: newValue})

    getPathname = (pathString) => {
        const path= pathString && pathString.split('files/')
        let jsxPath = ''
        jsxPath = path && path[1] ?
            <div>
                <span className="text-underline">Depôt : </span>
                <span className="font-weight-bold">{path[1]}</span>
            </div>
            : null
        return jsxPath;
    }
    getStyleOfIcon = (type, slug) => {
        let style = {}
        if (getIcon(type, slug).color) {
            style.color = getIcon(type, slug).color
        }
        if ( type !== 1 ) {
            style.fontSize ='20px' 
        }
        return style;
    }
    handleChangeBatchDelete = (e, idFolderToDelete) => {
        const {selectedToBatchDelete} = this.state;
        if (e.target.checked) {
            this.setState({selectedToBatchDelete:[...selectedToBatchDelete, idFolderToDelete] });
        } else {
            selectedToBatchDelete.includes(idFolderToDelete) &&
                this.setState({selectedToBatchDelete: selectedToBatchDelete.filter(elem => elem !== idFolderToDelete)});
        }
        selectedToBatchDelete ? this.setState({showBtnGroupedActions:true}) : this.setState({showBtnGroupedActions:false})
    }
    handleChangeBatchDeletePermanent = (e, idFolderToDelete) => {
        const {selectedToBatchDeletePermanently} = this.state;
        if (e.target.checked) {
            this.setState({selectedToBatchDeletePermanently:[...selectedToBatchDeletePermanently, idFolderToDelete] });
        } else {
            selectedToBatchDeletePermanently.includes(idFolderToDelete) &&
                this.setState({selectedToBatchDeletePermanently: selectedToBatchDeletePermanently.filter(elem => elem !== idFolderToDelete)});
        }
        selectedToBatchDeletePermanently ? this.setState({showBtnGroupedActions:true}) : this.setState({showBtnGroupedActions:false})
    }
    
    render() {
        const { filesTree, loadingFiles, errorFiles, loadingDepotContent, errorDepotContent, depotContent, currentDepot, access,errorPathname, currentPathnameString} = this.props;
        const {isUploadingFile, showModalAddFolder, showModalRenameFolder,showModalCopyFolder,loadingDelete, showModalMoveFolder, isInRecycleBin, isDownloading, selectedToBatchDelete, selectedToBatchDeletePermanently } = this.state;
    return (
        <>
            <Layout>
                {(loadingDepotContent || loadingDelete)  && <Loader />} 
                {errorDepotContent && <Message variant='danger'>{errorDepotContent}</Message> }
                {!loadingDepotContent && !errorDepotContent && depotContent && depotContent.length>0 ?
                    depotContent[0].children && depotContent[0].children.length>0 ?
                            <>
                                <div>
                                    <div className="page-header d-flex justify-content-between">
                                        <h2></h2>
                                        <a href="#" className="files-toggler">
                                            <i className="ti-menu"></i>
                                        </a>
                                    </div>
                                </div>
                                <div className="row">
                                    {isDownloading && <FadeLoader />}
                                    <ArboLeft depotContent= {depotContent} selectedDepot={currentDepot.slug} updateIsInRecycle={this.updateIsInRecycle} />
                                    <div className="col-xl-9">
                                        <div className="content-title mt-0">
                                            <h4>Documents</h4>
                                        </div>
                                        <div className="d-md-flex justify-content-between mb-4">
                                            <ul className="list-inline mb-3">
                                                {!isInRecycleBin && access.includes("write") && 
                                                    <li className="list-inline-item mb-0">
                                                    
                                                        <a href="#" className="btn btn-outline-light dropdown-toggle" data-toggle="dropdown">
                                                            Ajouter
                                                        </a>
                                                        <div className="dropdown-menu">
                                                            <label className="dropdown-item" onClick={() => this.setState({showModalAddFolder:true})}>
                                                            Nouveau dossier
                                                            </label>
                                                            <label className="dropdown-item">
                                                                Nouveau fichier <input type="file" accept=".avi,.bmp,.csv,.doc,.docx,.flv,.gif,.html,.jpeg,.jpg,.mht,.mp4,.msg,.pdf,.php,.png,.ppsx,.ppt,.pptm,.pptx,.RDP,.rtf,.ser,.tif,.wmv,.xls,.xlsb,.xlsm,.xlsx,.xps,.zip,.txt" hidden  onChange={this.uploadFileHandler} />
                                                            </label>
                                                        
                                                        </div>
                                                    </li>
                                                }
                                                {(selectedToBatchDelete.length>0 || selectedToBatchDeletePermanently.length>0)&&
                                                    <DropdownButton 
                                                        as={ButtonGroup} 
                                                        title="Actions groupées"
                                                        id="actions-dropdown"
                                                    >
                                                        {selectedToBatchDelete.length>0 ? 
                                                            <Dropdown.Item 
                                                                onClick={this.deleteBatchDocumentHandler}
                                                            > 
                                                                Supprimer 
                                                            </Dropdown.Item> :
                                                            <Dropdown.Item 
                                                                onClick={this.batchDeletePermanentHandler}
                                                            >
                                                                Suppression définitive
                                                            </Dropdown.Item>
                                                        }
                                                    </DropdownButton>
                                                }
                                            </ul>
                                        </div>
                                        {errorPathname ? <p>{errorPathname}</p> : this.getPathname(currentPathnameString)}
                                        {showModalAddFolder && 
                                            <Modal show={showModalAddFolder} onHide={() => this.setState({showModalAddFolder:false})}>
                                                <Modal.Header closeButton>
                                                <Modal.Title>Ajouter un dossier</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Form.Group controlId='Réference'>
                                                        <Form.Label>Nom</Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            placeholder='Entrer un nom'
                                                            value={this.state.newFolderName}
                                                            onChange={(e) => this.setState({newFolderName: e.target.value})}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                <Button variant="danger" onClick={() => this.setState({showModalAddFolder:false})}>
                                                    Annuler
                                                </Button>
                                                <Button variant="success" onClick={this.addFolderHandler} disabled={!this.state.newFolderName}>
                                                    Valider
                                                </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        }
                                        {showModalRenameFolder && 
                                            <Modal show={showModalRenameFolder} onHide={() => this.setState({showModalRenameFolder:false,selectedFolderName:null})}>
                                                <Modal.Header closeButton>
                                                <Modal.Title>Renommer le dossier {this.state.selectedFolderName}</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Form.Group controlId='NameFolder'>
                                                        <Form.Label>Nom</Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            placeholder='Entrer le nouveau nom'
                                                            defaultValue={this.state.selectedFolderName}
                                                            onChange={(e) => this.setState({newFolderName: e.target.value})}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                <Button variant="danger" onClick={() => this.setState({showModalRenameFolder:false,idFolderToEdit:null,selectedFolderName:null})}>
                                                    Annuler
                                                </Button>
                                                <Button variant="success" onClick={this.renameFolderHandler} disabled={this.state.operationEnCours || !this.state.newFolderName}>
                                                    {this.state.operationEnCours ? '...loading' : 'Valider'}
                                                </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        }
                                        {(showModalCopyFolder || showModalMoveFolder) && 
                                            <Modal 
                                                show={showModalCopyFolder|| showModalMoveFolder} 
                                                onHide={() => this.setState({
                                                    showModalCopyFolder:false,idFolderToEdit:0,NewFolderPath:null,
                                                    showModalMoveFolder:false, OldFolderName:null, fatherOfSelectedFolder: null})}
                                            >
                                                <Modal.Header closeButton>
                                                <Modal.Title>{`${showModalMoveFolder ? 'déplacer':'Copier'} le dossier ${this.state.OldFolderName || ''} vers`}</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Form.Group controlId='NameFolder'>
                                                        <Form.Label>Contenu du depot</Form.Label>
                                                        { showModalCopyFolder ? <div id='filesCopyModal'></div> : <div id='filesMoveModal'></div>}
                                                    </Form.Group>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                <Button 
                                                    variant="danger" 
                                                    onClick={() => this.setState({
                                                        showModalCopyFolder:false,idFolderToEdit:null,NewFolderPath:null,
                                                        showModalMoveFolder:false, OldFolderName:null, fatherOfSelectedFolder: null
                                                    })}
                                                >
                                                    Annuler
                                                </Button>
                                                <Button 
                                                    variant="success" 
                                                    onClick={showModalMoveFolder ? this.moveFolderHandler : this.copyFolderHandler}
                                                    disabled={!this.state.isActifBtnCopyOrMove}
                                                >
                                                    Valider
                                                </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        }

                                        <div className="table">
                                        <div  className="d-sse">  
                                        </div>
                                            {(loadingFiles || isUploadingFile) ? <Loader />:
                                                errorFiles ? <Message variant='danger'>{errorFiles}</Message> :
                                                <div className="table">
                                                    <table id="table-files" className="table table-borderless table-hover">
                                                    { filesTree && filesTree.length>0 && access && filesTree[0].children && filesTree[0].children.length>0 &&
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Nom</th>
                                                            <th>Derniére modification</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    }
                                                    <tbody>
                                                        { filesTree && filesTree.length>0 && access && filesTree[0].children && filesTree[0].id_parent !== 0 &&
                                                        <tr>
                                                            <td colSpan="3">
                                                                <div 
                                                                    className="d-flex align-items-center" 
                                                                    style={{cursor:'pointer', background: 'rgba(0,0,0,.05)' }}
                                                                    title="Cliquer pour revenir en arrière"
                                                                    onClick={(e)=> this.goToParent(e, filesTree[0])}
                                                                >
                                                                    <figure className="avatar avatar-sm mr-3 bg-bleu-charte">
                                                                        <span className={`avatar-title rounded-pill`}>
                                                                            <i 
                                                                                className="ti-back-right"
                                                                            >
                                                                            </i> 
                                                                        </span>
                                                                    </figure>
                                                                    <span className="d-flex flex-column">
                                                                        <span className="text-primary" >Retour</span>
                                                                        {/* <span className="small font-italic">{(file.size/1000).toFixed(2)} ko</span> */}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        }
                                                        { filesTree && filesTree.length>0 && access && filesTree[0].children && filesTree[0].children.length>0 &&  filesTree[0].children.map((file, idx) =>(
                                                            <tr key={file.slug}>
                                                                <td>
                                                                    {file.is_archive !== 1 && file.type !== 3 ?
                                                                        <Form.Check 
                                                                            aria-label="delete"
                                                                            onChange={(e) => this.handleChangeBatchDelete(e, file.id)}
                                                                        />:
                                                                        <Form.Check 
                                                                            aria-label="delete"
                                                                            onChange={(e) => this.handleChangeBatchDeletePermanent(e, file.id)}
                                                                        />
                                                                    }
                                                                    
                                                                </td>
                                                                <td>
                                                                    <div 
                                                                        className="d-flex align-items-center" 
                                                                        style={{cursor:'pointer'}}
                                                                        onClick={(e)=>{file.type === 2 ? this.changeFileInfos(e, file.id) : this.changePathname(e, file);
                                                                                       file.type === 1 && this.props.saveLogActionUser(file.id,'1');
                                                                                      }}
                                                                        >
                                                                        <figure className="avatar avatar-sm mr-3">
                                                                            <span className={`avatar-title ${file.type === 1  ? `bg-warning` : ''} rounded-pill`}>
                                                                                <i 
                                                                                    className={getIcon(file.type, file.slug).icon} 
                                                                                    style={this.getStyleOfIcon(file.type, file.slug)}
                                                                                >
                                                                                </i> 
                                                                            </span>
                                                                        </figure>
                                                                        <span className="d-flex flex-column">
                                                                            <span className="text-primary" >{file.text}</span>
                                                                            {/* <span className="small font-italic">{(file.size/1000).toFixed(2)} ko</span> */}
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td>{dateFormatTexte(file.date_update)}</td>
                                                                <td>
                                                                    <div className="dropdown">
                                                                        <a href="#" className="btn btn-floating" data-toggle="dropdown">
                                                                            <i className="ti-more-alt"></i>
                                                                        </a>
                                                                        <div className="dropdown-menu dropdown-menu-right">
                                                                            {(file.type === 1 || file.type === 3)  && (access.includes("read") || access.includes("write")) &&
                                                                                <div 
                                                                                    className="dropdown-item pointer" 
                                                                                    onClick={(e)=> {this.changeFileInfos(e, file.id);this.changePathname(e, file);this.props.saveLogActionUser(file.id,'1')}}
                                                                                >
                                                                                    Voir contenu
                                                                                </div>
                                                                            }
                                                                            {file.is_archive === 1  && file.id_parent_to_restore &&  (access.includes("read") || access.includes("write")) &&
                                                                                <div 
                                                                                    className="dropdown-item pointer" 
                                                                                    onClick={(e)=> this.restoreDocument(e, file.id, file.id_parent)}
                                                                                >
                                                                                    Restaurer
                                                                                </div>
                                                                            }
                                                                            {(access.includes("read") || access.includes("write")) && file.type === 2 && file.is_archive === 0 && 
                                                                                <div 
                                                                                    className="dropdown-item pointer"
                                                                                    onClick={(e) => {this.downloadFileHandler(e, file.id, file.slug);this.props.saveLogActionUser(file.id,'2')}}

                                                                                >
                                                                                    Télécharger
                                                                                </div>
                                                                            }
                                                                            {access.includes("write") &&
                                                                                <>
                                                                                    {file.is_archive === 0 && file.type !== 3 && 
                                                                                    <>
                                                                                    <div 
                                                                                        className="dropdown-item pointer" 
                                                                                        onClick={() => {this.setState({showModalCopyFolder:true, idFolderToEdit: file.id, OldFolderName:file.slug, fatherOfSelectedFolder: file.id_parent})}}
                                                                                    >
                                                                                        Copier
                                                                                    </div>
                                                                                    <div 
                                                                                        className="dropdown-item pointer"
                                                                                        onClick={() => {this.setState({showModalMoveFolder:true,idFolderToEdit: file.id, selectedFolderName: file.slug, fatherOfSelectedFolder: file.id_parent})}}
                                                                                    >
                                                                                        Déplacer vers 
                                                                                    </div>
                                                                                    <div  
                                                                                        className="dropdown-item pointer" 
                                                                                        onClick={() => {this.setState({showModalRenameFolder:true, idFolderToEdit: file.id, selectedFolderName: file.slug, fatherOfSelectedFolder: file.id_parent})}}
                                                                                    >
                                                                                        Renommer
                                                                                    </div>
                                                                                    </>
                                                                                    }
                                                                                    {file.type !== 3 &&
                                                                                    <div 
                                                                                        className="dropdown-item pointer"
                                                                                        onClick={(e) => 
                                                                                            {file.is_archive === 0?
                                                                                                this.deleteDocumentHandler(e, file.id, file.id_parent):
                                                                                                this.deletePermanentDocumentHandler(e, file.id, file.id_parent)}
                                                                                            
                                                                                        }
                                                                                    >
                                                                                        Supprimer
                                                                                    </div>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>  
                                                        ))}
                                                    </tbody>
                                                    
                                                </table>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        :   currentDepot.id ? 
                                <Message variant='info'>
                                    <p className="text-center">Votre depôt est vide</p>
                                    <p className="text-center">Remplissez-le en créant un
                                        <label className="pointer ml-1 badge badge-primary text-wrap" onClick={() => this.setState({showModalAddFolder:true})}>
                                            Nouveau dossier
                                        </label>
                                        {showModalAddFolder && 
                                            <Modal show={showModalAddFolder} onHide={() => this.setState({showModalAddFolder:false})}>
                                                <Modal.Header closeButton>
                                                <Modal.Title>Ajouter un dossier</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Form.Group controlId='Réference'>
                                                        <Form.Label>Nom</Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            placeholder='Entrer un nom'
                                                            value={this.state.newFolderName}
                                                            onChange={(e) => this.setState({newFolderName: e.target.value})}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                    {isUploadingFile && <Loader /> }
                                                </Modal.Body>
                                                <Modal.Footer>
                                                <Button variant="danger" onClick={() => this.setState({showModalAddFolder:false})}>
                                                    Annuler
                                                </Button>
                                                <Button variant="success" onClick={this.addFolderHandler} disabled={isUploadingFile}>
                                                    Valider 
                                                </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        }
                                    {/* <label className="pointer ml-1">
                                        Envoyer <input type="file" hidden  onChange={this.uploadFileHandler} />
                                    </label> */}
                                    </p>
                                    
                                </Message>
                            :<Message variant='danger'>Pas de depôts trouvé!</Message>//<Redirect to="/404" />
                    :loadingFiles ? <Loader /> : null//<Message variant='info'>Pas de depôts trouvé!</Message>
                }
            </Layout>
        </>
    )
}
}

const mapStateToProps = ({depotContent, auth, filesTree, depotsList}) => ({
    authenticatedUser : auth.userInfo,
    selectedPath: filesTree.currentPathname,
    currentPathnameString: filesTree.currentPathnameString,
    errorPathname: filesTree.errorPathname,
    filesTree: filesTree.filesTree,
    errorFiles: filesTree.error,
    loadingFiles: filesTree.loading,
    loadingPathname : filesTree.loadingPathname,
    depotContent: depotContent.depotContent,
    errorDepotContent: depotContent.error,
    loadingDepotContent: depotContent.loading,
    access : depotContent.access,
    currentDepot: depotsList.currentDepot,
    listDepots: depotsList.depots,
})

const mapDispatchToProps = {
    fetchFilesTree,
    updateActivePathname,
    getDepotContent,
    updateActiveFileInfos,
    setModalStatus,
    saveLogActionUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);

