import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button } from 'react-bootstrap'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Layout from './Layout';
import FormContainer from '../components/FormContainer'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getUserDetails, updateUser } from '../store/actions/userActions';
import { isAuthenticated, updateUserPasswd } from '../store/actions/authAction';
import {getUrlSiteByEnv} from '../utils/function';
import API from '../utils/API';
import { USER_UPDATE_RESET } from '../store/actions/types'

const UserListScreen = () => {
    
    const dispatch = useDispatch()

    const authInfos = useSelector((state) => state.auth)
    const { userInfo, successUpdatePasswd, loading: loadingUpdatePasswd, error: errorUpdatePasswd} = authInfos

    const userDetails = useSelector((state) => state.userDetails)

    const { loading, error, user } = userDetails

    const userUpdate = useSelector((state) => state.userUpdate)
    const { loading:loadingUpdate, error:errorUpdate, success:successUpdate } = userUpdate

    const [nomPrenom, setNomPrenom] = useState('')

    const [image, setImage] = useState('')
    const [uploading, setUploading] = useState(false)
    const [uploadingError, setUploadingError] = useState('')
    const [deleteImageError, setDeleteImageError] = useState('')

    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPassword2, setNewPassword2] = useState('')
    const [passwordError, setPasswordError] = useState('')

    useEffect(() => {
        if (successUpdate) {
            NotificationManager.success('Modification effectuées avec succés', 'Succés', 5000);
            dispatch({ type: USER_UPDATE_RESET })
            dispatch(isAuthenticated())
        }
        if (userInfo && !user ) {
            dispatch(getUserDetails(userInfo.id_user))
        }else if(user && user.ref_user){
            setImage(user.image)
            setNomPrenom(user.nom_prenom)
        }
        
    }, [dispatch, user,userInfo, successUpdate])
    const submitHandler = (e) => {
        e.preventDefault()

        const submittedUser = {
            nom_prenom:nomPrenom
        }
        dispatch(updateUser(user.id_user, submittedUser))
    }
    const changePasswdHandler = (e) => {
        e.preventDefault()
        if (newPassword.length < 8 || password.length < 8 ) {
            setPasswordError('Mot de passe trop court')
            return;
        }
        if(newPassword !== newPassword2 ) {
            setPasswordError('Mot de passe non identique')
            return;
        }
        Number(userInfo.id_role) === 1 ?
            dispatch(updateUserPasswd(true, 0, password, newPassword)) :
            dispatch(updateUserPasswd(false, null, password, newPassword))
    }

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('image', file)
        setUploading(true)
    
        try {
            const response = await API.post(`/users/updateImage/${user.id_user}`, formData)
            const { data:{success, uploadedImage }} = response;
            if (success) {
                setImage(uploadedImage)
                setUploading(false)
                setUploadingError('')
            }
        } catch (err) {
            console.error(err.message)
            setUploadingError(err.response && err.response.data ? err.response.data.error : err.message)
            setUploading(false)
        }
    }

    const deleteImageHandler = (e) => {
        e.preventDefault()
        const deleteImage = async() => {
            try {
                const response = await API.get(`/users/deleteImage/${user.id_user}`)
                const { data:{success }} = response;
                if (success) {
                    setImage('')
                }
            } catch (err) {
                console.error(err.message)
                setDeleteImageError(err.response && err.response.data ? err.response.data.error : err.message)
            }
        }
        confirmAlert({
            title: 'Confirmation',
            message: 'Etes-vous sûr de vouloir supprimer votre avatar?',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => deleteImage()
                },
                {
                    label: 'Non',
                    onClick: () => null
                }
            ]
        });
    }
    
    return (
        <Layout>
            <div className="page-header d-flex justify-content-between">
                <div className="content-title" style={{width: "100%"}}>
                    <h4>Mon profil</h4>
                </div>
            </div>
            <div>
                {(loadingUpdate || loadingUpdatePasswd) && <Loader />}
                {(errorUpdate || errorUpdatePasswd || passwordError) && <Message variant='danger'>{errorUpdate || errorUpdatePasswd || passwordError}</Message>}
                {successUpdatePasswd && <Message variant='success'>Vos modifications ont été effectués avec succés!</Message>}
            </div>
            {!user && error ? 
                <Message variant='danger'> {error} </Message>
                :
                <>
                    <ul className="nav nav-tabs" style={{margin: "52px"}}  role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="infos-tab" data-toggle="tab" href="#infos" role="tab"
                            aria-controls="infos" aria-selected="true">
                                Informations générale
                            </a>
                        </li>
                        <li className="nav-item">
                            <a  className="nav-link" id="passwd-tab" data-toggle="tab" href="#passwd" role="tab"
                                aria-controls="passwd" aria-selected="false">
                                Changer mot de passe
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content">
                    <div className="tab-pane fade show active" id="infos" role="tabpanel" aria-labelledby="infos-tab">
                        {loading ? 
                            <Loader /> : 
                            <div className="row">
                                <div className="col-sm-3">
                                <div className="text-center" style={{margin: '50px 0'}}>
                                    { image ?
                                        <figure className="avatar avatar-xxl avatar-edit">
                                            <div className="clicked-avatar" style={{cursor:'pointer'}} onClick={deleteImageHandler}></div>
                                            <img
                                                className="rounded-circle img-fluid mb-3" 
                                                src={`${getUrlSiteByEnv()}/${process.env.REACT_APP_USER_IMAGE_FOLDER}/${image}`} 
                                                alt="user" 
                                            /> 
                                        </figure> :
                                        <figure className="avatar avatar-xxl ">
                                            <span className="avatar-title rounded-circle">
                                                <i className="far fa-user" style={{fontSize: '54px'}} ></i> 
                                            </span>
                                        </figure>
                                    }
                                </div>

                                </div>
                                <div className="col-sm-9">
                                    <FormContainer>
                                        <Form onSubmit={submitHandler}>
                                            <Form.Group controlId='Réference'>
                                                <Form.Label>Réference</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    placeholder='Entrer une réference'
                                                    value={user ?user.ref_user : ''}
                                                    disabled
                                                ></Form.Control>
                                            </Form.Group>
                                            <Form.Group controlId='email'>
                                                <Form.Label>Adresse Email</Form.Label>
                                                <Form.Control
                                                    type='email'
                                                    placeholder='Entrer email'
                                                    value={user ?user.email : ''}
                                                    disabled
                                                ></Form.Control>
                                            </Form.Group>
                                            <Form.Group controlId='nomPrenom'>
                                                <Form.Label>Nom/prénom</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    placeholder='Entrer Nom/prénom'
                                                    value={nomPrenom}
                                                    onChange={(e) => setNomPrenom(e.target.value)}
                                                ></Form.Control>
                                            </Form.Group>
                                            
                                            
                                            <Form.Group controlId='image'>
                                                <Form.Label>Image</Form.Label>
                                                <Form.File
                                                    id='image-file'
                                                    label='Choisir une image'
                                                    custom
                                                    onChange={uploadFileHandler}
                                                ></Form.File>
                                                {uploading && <Loader />}
                                                {(uploadingError || deleteImageError) && <Message variant='danger'>{uploadingError || deleteImageError}</Message>}
                                            </Form.Group>
                                            
                                            <div className="text-center mt-4">
                                                <Button type='submit' variant='primary'>
                                                    Modifier mon profil
                                                </Button>
                                            </div>
                                        </Form>
                                    </FormContainer>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="tab-pane fade" id="passwd" role="tabpanel" aria-labelledby="passwd-tab">
                        <FormContainer>
                            <Form onSubmit={changePasswdHandler}>
                                <Form.Group controlId='passwd'>
                                    <Form.Label>Mot de passe actuel</Form.Label>
                                    <Form.Control
                                        type='password'
                                        placeholder='Mot de passe actuel'
                                        onChange={(e) => setPassword(e.target.value)}
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='passwd'>
                                    <Form.Label>Nouveau mot de passe</Form.Label>
                                    <Form.Control
                                        type='password'
                                        placeholder='Nouveau Mot de passe'
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    ></Form.Control>
                                </Form.Group>
                                
                                <Form.Group controlId='passwd'>
                                    <Form.Label>Retaper le nouveau mot de passe</Form.Label>
                                    <Form.Control
                                        type='password'
                                        placeholder='Mot de passe'
                                        onChange={(e) => setNewPassword2(e.target.value)}
                                    ></Form.Control>
                                </Form.Group>

                                <Button type='submit' variant='primary'>
                                    Modifier
                                </Button>
                            </Form>
                        </FormContainer>
                    </div>
                    
                </div>
                </>
            }
        </Layout>
    )
}

export default UserListScreen
