import React, { Component } from 'react'
import { connect } from 'react-redux';
import $ from 'jquery'
import 'jstree/dist/themes/default/style.min.css'
import 'jstree/dist/jstree.min.js'
import { updateActivePathname, getFileInfos } from '../store/actions/listDocumentAction';
import { saveLogActionUser } from '../store/actions/historiquesUserAction';
import { setModalStatus } from '../store/actions/utils';
import { Button } from "react-bootstrap";

class ArboLeft extends Component {
    state= {}
    componentDidMount() {
        const {depotContent, updateActivePathname, selectedDepot, updateIsInRecycle, getFileInfos, currentPathname } = this.props;
        $("#files").jstree("destroy");
        const jsonData = {
            'data': depotContent,
            themes: {
                dots: false,
            },
            check_callback  : true,
        };
            
        $('#files')
            .on('ready.jstree', function (e, data) {
                const dataElement = data.instance._model.data;
                for (const elem in dataElement) {
                    if (elem !== '#') {
                        //rename the nodes after load car api send "name" and we have to fill "text" for jstree
                        //$('#files').jstree('rename_node', dataElement[elem], dataElement[elem].original.name);
                        
                        //select the node of the depot on load
                        dataElement[elem].original.type ===3 ? 
                            data.instance.set_icon(dataElement[elem], "fa fa-trash-alt") :
                            data.instance.set_icon(dataElement[elem], "fa fa-folder") 
                        dataElement[elem].original.name === selectedDepot && $('#files').jstree('select_node', dataElement[elem]);
                    }
                }
                
                if( depotContent && depotContent.length && depotContent[0].id && $('#files').jstree(true)){
                    const nodeToSelect = $('#files').jstree(true).get_node(depotContent[0].id, true)
                    // $('#files').jstree(true).deselect_all(true)
                    
                    // $('#files').jstree('deselect_all', nodeToSelect);
                    // $('#files').jstree(true)._open_to(currentPathname);
                    $("#files").jstree("open_node",nodeToSelect, function(e, data) {
                        $('#files').jstree('select_node', currentPathname);
                    }, true);
                }
            })
            .on('changed.jstree', function (e, data) {
                $('#files').jstree('select_node', data.node);
                data.node && data.node.original && updateActivePathname( data.node.original.id)
                data.node && data.node.original && getFileInfos(data.node.original.id)
                if(data.node && data.node.original){
                    if(data.node.original.is_archive === 1 || data.node.original.type === 3){
                        updateIsInRecycle(true)
                    }else{
                        updateIsInRecycle(false)
                    }
                }
            })
            .on('hover_node.jstree', function (e, data) {
                const node = $("#" + data.node.id);
                node.find('a').attr('title',data.node.text);
            })
            .jstree({
            'core':  jsonData,
            "types": {
                "directory": {
                    "icon": "ti-folder text-warning",
                },
                "file": {
                    "icon": "ti-file",
                }
            },
            plugins: ["types", "search"]
        });
    }
    componentDidUpdate(prevProps, prevState) {
        const { currentPathname,saveLogActionUser} = this.props;
 
        if (prevProps.currentPathname !== currentPathname) {
            if( $('#files').jstree(true)){
                const nodeToSelect = $('#files').jstree(true).get_node(currentPathname, true)
                // $('#files').jstree(true).deselect_all(true)
                
                $('#files').jstree('deselect_all', nodeToSelect);
                $('#files').jstree(true)._open_to(currentPathname);
                $("#files").jstree("open_node",nodeToSelect, function(e, data) {
                    saveLogActionUser(currentPathname,'1');
                    $('#files').jstree('select_node', currentPathname);
                }, true);
            }
        }
        
    }
    itemSubDossier = item =>  this.props.allDOSSIER.filter(elem => elem.id_parent===(item.id_dossier ? item.id_dossier :0));
    handleChangeSearchJstree = e => $('#files').jstree(true).search(e.target.value);

    render() {
        const { depotContent, setModalStatus} = this.props;
        return (
            <> 
                <div className="col-xl-3 files-sidebar">
                    <div className="card border-0">
                        <h6 className="card-title">{depotContent[0].name} </h6>
                        <div className="form-group">
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Rechercher" 
                                onChange={this.handleChangeSearchJstree} 
                            />
                        </div>
                        <div id="files">  
                        </div>
                        <Button className="mt-5 text-center bg-bleu-charte" onClick={() => setModalStatus(true)}>
                            Mes dépôts
                        </Button>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ filesTree }) => ({
    currentPathname: filesTree.currentPathname
})

const mapDispatchToProps = {
    updateActivePathname,
    getFileInfos,
    setModalStatus,
    saveLogActionUser
}

export default connect(mapStateToProps, mapDispatchToProps)(ArboLeft);