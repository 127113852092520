import {
    UPDATE_MODAL_DEPOT_STATE
} from '../constants/utils'

// Set Modal Depot
export const setModalStatus = (status) => (dispatch) => {
    dispatch({
        type: UPDATE_MODAL_DEPOT_STATE,
        payload: status,
    })

};
