import { 
    FETCH_ALL_DOSSIER, DOSSIER_ERROR, FETCH_ONE_DOSSIER, SET_LOADING_DOSSIER, FETCH_INDEX_DOSSIER,
    FOLDERS_ACTIVE_AND_PARENT_LIST_REQUEST, FOLDERS_ACTIVE_AND_PARENT_LIST_SUCCESS, FOLDERS_ACTIVE_AND_PARENT_LIST_FAIL,
    FILES_TREE_REQUEST, FILES_TREE_SUCCESS, FILES_TREE_FAIL, 
    SET_CURRENT_PATHNAME,  PATHNAME_REQUEST, PATHNAME_FAIL,
    DEPOT_TREE_REQUEST, DEPOT_TREE_SUCCESS, DEPOT_TREE_FAIL,
    FILE_INFO_REQUEST, FILE_INFO_SUCCESS, FILE_INFO_FAIL, SET_CURRENT_FILEINFOS, FILE_INFO_RESET,
    STATS_REQUEST, STATS_SUCCESS, STATS_FAIL
} from './types';
import API from '../../utils/API';

export const fetchAllDossier = () => async(dispatch) => {
    try {
        dispatch(setLoading());
        const allDOSSIER = await API({ method: 'get', url: `dossiers/arboresence` });
        dispatch({
            type: FETCH_ALL_DOSSIER,
            payload: allDOSSIER.data.results
        })
    } catch (err) {
        dispatch({
            type: DOSSIER_ERROR,
            payload: err.message
        });
    }
};

export const fetchOneDossier = (slug, idDossier) => async dispatch => {
    try {
        dispatch(setLoading())
        const DOSSIER = await API({ method: 'get', url: `/api/v1/dossiers/filter?actif=1&id_dossier=${idDossier}&slug='${slug}'` });
        dispatch({
            type: FETCH_ONE_DOSSIER,
            payload: DOSSIER.data.dossier
        })
    } catch (err) {
        dispatch({
            type: DOSSIER_ERROR,
            payload: err.message
        });
    }
};

export const fetchIndexDOSSIER = idDossier => async dispatch => {
    try {
        dispatch(setLoading())
        const DOSSIER = await API({ method: 'get', url: `/api/v1/dossiers/filter?actif=1&id_dossier=${idDossier}&slug='home'` });
        dispatch({
            type: FETCH_INDEX_DOSSIER,
            payload: DOSSIER.data.dossier
        })
    } catch (err) {
        dispatch({
            type: DOSSIER_ERROR,
            payload: err.message
        });
    }
};

export const listActiveParentFolders = () => async(dispatch) => {
    try {
        dispatch({ type: FOLDERS_ACTIVE_AND_PARENT_LIST_REQUEST })

        const { data } = await API.get(`/dossiers/filter?actif=1&id_parent=0&type=1`)
        
        dispatch({
            type: FOLDERS_ACTIVE_AND_PARENT_LIST_SUCCESS,
            payload: data.dossiers,
        })
    } catch (error) {
        const message =
            error.response && error.response.data.error ?
            error.response.data.error :
            error.message
        dispatch({
            type: FOLDERS_ACTIVE_AND_PARENT_LIST_FAIL,
            payload: message,
        })
    }
}


//fetch dossiers depot (directly under root)
export const fetchDepotTree = (isRootFolder, pathname) => async(dispatch) => {
    try {
        dispatch({ type: DEPOT_TREE_REQUEST })
        let queryFolder = '';
        isRootFolder ? queryFolder= '': queryFolder= `&folder=${pathname}`
        const {data} = await API({ method: 'get', url: `dossiers/folderToJson?onlyFolder=true${queryFolder}` });
        dispatch({
            type: DEPOT_TREE_SUCCESS,
            payload: data.jsonStructure
        })
    } catch (error) {
        const message =
            error.response && error.response.data ?
                error.response.data.error :
                error.message
        dispatch({
            type: DEPOT_TREE_FAIL,
            payload: message
        });
    }
};

export const fetchFilesTree = (id_dossier) => async(dispatch) => {
    try {
        dispatch({ type: FILES_TREE_REQUEST })
        const {data} = await API({ method: 'get', url: `dossiers/contentStructure?id_dossier=${id_dossier}` });
        dispatch({
            type: FILES_TREE_SUCCESS,
            payload: data.structure
        })
    } catch (error) {
        const message =
            error.response && error.response.data ?
            error.response.data.error :
            error.message
        dispatch({
            type: FILES_TREE_FAIL,
            payload: message
        });
    }
};

export const updateActivePathname = (id_dossier) => async(dispatch) => {
    try {
        dispatch({ type: PATHNAME_REQUEST })
        const {data} = await API({ method: 'get', url: `dossiers/pathname?id_dossier=${id_dossier}` });
        dispatch({
            type: SET_CURRENT_PATHNAME,
            payload: {id: id_dossier, pathname:data.pathname}
        })
    } catch (error) {
        const message =
            error.response && error.response.data ?
            error.response.data.error :
            error.message
        dispatch({
            type: PATHNAME_FAIL,
            payload: {error :message, id:id_dossier}
        });
    }
   
};

// Set loading to true
export const setLoading = () => {
    return {
        type: SET_LOADING_DOSSIER
    };

};


export const getFileInfos = (id_dossier) => async(dispatch) => {
    try {
        dispatch({ type: FILE_INFO_REQUEST })

        const { data } = await API.get(`/dossiers/getFileInfos?id_dossier=${id_dossier}`)
        
        dispatch({
            type: FILE_INFO_SUCCESS,
            payload: data,
        })
    } catch (error) {
        const message =
            error.response && error.response.data.error ?
            error.response.data.error :
            error.message
        dispatch({
            type: FILE_INFO_FAIL,
            payload: message,
        })
    }
}

export const updateActiveFileInfos = (id_dossier) => {
    return {
        type: SET_CURRENT_FILEINFOS,
        payload: id_dossier
    };
};
export const resetFileInfos = () => {
    return {
        type: FILE_INFO_RESET,
    };
};

export const getStats = (id_dossier) => async(dispatch) => {
    try {
        dispatch({ type: STATS_REQUEST })

        const { data } = await API.get(`/dossiers/stats${id_dossier?'?id_dossier=': ''}`)
        
        dispatch({
            type: STATS_SUCCESS,
            payload: data.stats,
        })
    } catch (error) {
        const message =
            error.response && error.response.data ?
            error.response.data.error :
            error.message
        dispatch({
            type: STATS_FAIL,
            payload: message,
        })
    }
}
