import { useSelector, useDispatch } from 'react-redux';
import {useState, useEffect} from 'react';
import IdleTimer from 'react-idle-timer';
import { Route, Switch } from 'react-router-dom';
import {NotificationContainer} from 'react-notifications';
import Homepage from "./Homepage";
import UserListScreen from "./Users/UserListScreen";
import UserDetailsScreen from "./Users/UserDetailsScreen";
import Page404 from "./Page404";
import DepotListe from './depots/depotListScreen';
import LogsListe from './logs_actions/LogsListScreen';
import RoleListScreen from "./Roles/RoleListScreen";
import RoleDetailsScreen from './Roles/RoleDetailsScreen';
import Login from './Login';
import SecuredRoute from '../utils/HOC/secureRoute';
import { isAuthenticated, logout } from '../store/actions/authAction';
import { fetchDepotList } from '../store/actions/depotActions';
import Forget from './Forget';
import Reset from './Reset';
import UserProfilScreen from './UserProfilScreen';

const Router = () => {
    const [isTimedOut, setIsTimedOut] = useState(false)
    const [idleTimer, setIdleTimer] = useState(null)

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(isAuthenticated())
    }, [dispatch])

    const {isAuth, loading, userInfo} = useSelector((state) => state.auth);
    const {depots, loading:loadingDepotList, error: errorDepotList} = useSelector((state) => state.depotsList);
    
    useEffect(() => {
        isAuth && !depots && !errorDepotList && !loadingDepotList && dispatch(fetchDepotList())
    }, [isAuth, depots, loadingDepotList, errorDepotList, dispatch])
    
    const onAction = (e)  => {
        // console.log('user did something', e)
        setIsTimedOut(false)
    }
    
    const onActive = (e)  => {
        // console.log('user is active', e)
        setIsTimedOut(false)
    }
    
    const onIdle = (e)  => {
        // console.log('user is idle', e)
        if (isTimedOut) {
            dispatch(logout())
        } else {
            idleTimer.reset();
            setIsTimedOut(true)
        }
    }

    return (

            <>
                <IdleTimer
                    ref={ref => { setIdleTimer(ref)}}
                    element={document}
                    onActive={onActive}
                    onIdle={onIdle}
                    onAction={onAction}
                    debounce={250}
                    timeout={1000 * 30 *60}//30min
                />
                <div>
                    <NotificationContainer/>
                </div>
                <Switch>
                    <Route exact path="/" component={Login}  />
                    <Route exact path="/forget" component={Forget}  />
                    <Route exact path="/reset" component={Reset}  />

                    <SecuredRoute  exact isAdmin={true} roles={userInfo?.ref_role} isLoggedIn={isAuth} loading={loading} path="/logs_action" component={LogsListe}  />
                    <SecuredRoute  exact isAdmin={true} roles={userInfo?.ref_role} isLoggedIn={isAuth} loading={loading} path="/mes_depots" component={DepotListe}  />
                    <SecuredRoute  exact isLoggedIn={isAuth} loading={loading} path="/home" component={Homepage} /> 
                    <SecuredRoute  exact isAdmin={true} roles={userInfo?.ref_role} isLoggedIn={isAuth} loading={loading} path="/admin/users" component={UserListScreen} />
                    <SecuredRoute   
                        exact 
                        isAdmin={true} 
                        roles={userInfo?.ref_role} 
                        isLoggedIn={isAuth} 
                        loading={loading} 
                        path="/admin/user/create" 
                        component={UserDetailsScreen} 
                    />  
                    <SecuredRoute  
                        exact 
                        isAdmin={true} 
                        roles={userInfo?.ref_role} 
                        isLoggedIn={isAuth} 
                        loading={loading}
                        path="/admin/user/:id/edit" 
                        component={UserDetailsScreen} 
                    /> 
                    <SecuredRoute  
                        exact 
                        isLoggedIn={isAuth} 
                        loading={loading}
                        path="/profile" 
                        component={UserProfilScreen} 
                    /> 
                    <SecuredRoute  exact isAdmin={true} roles={userInfo?.ref_role} isLoggedIn={isAuth} loading={loading} path="/admin/roles" component={RoleListScreen} />

                    <SecuredRoute   
                        exact 
                        isAdmin={true} 
                        roles={userInfo?.ref_role} 
                        isLoggedIn={isAuth} 
                        loading={loading} 
                        path="/admin/role/create" 
                        component={RoleDetailsScreen} 
                    />  
                    <SecuredRoute  
                        exact 
                        isAdmin={true} 
                        roles={userInfo?.ref_role} 
                        isLoggedIn={isAuth} 
                        loading={loading}
                        path="/admin/role/:id/edit" 
                        component={RoleDetailsScreen} 
                    /> 

                    <Route path='/:slug' component={Page404} />

                </Switch>

            </>
        
    )
}

export default Router;