import { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../store/actions/authAction';
import Message from '../components/Message'
import Loader from '../components/Loader'

const Login = ({ location, history, ...props }) => {
    const [email, setEmail] = useState(localStorage.getItem('email') ||'')
    const [password, setPassword] = useState('')
    const handleChangeEmail = (e) => setEmail(e.target.value);
    const [isRemember, setIsRemember] = useState(true)
    const handleChangePassword = (e) => setPassword(e.target.value);
    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.auth);
    const { loading, error, userInfo, isAuth } = userLogin;

    useEffect(() => {
        if (userInfo) {
            history.push("/home")
        }
    }, [history, userInfo])

    const handleOnSubmit = e => {
        e.preventDefault();
        if (isRemember) {
            localStorage.setItem('email', email);
        } else {
            localStorage.removeItem('email');
        }
        dispatch(login(email, password))
    };

    return (
        <>
            {loading ? <Loader />:
                isAuth ? 
                    <Redirect to={{pathname:'/home'}} /> :
                        <div className="form-membership" style={{background: 'url(media/image/image1.jpg)'}}>
                            <div className="form-wrapper">

                                {/* <!-- logo --> */}
                                <div id="logo">
                                    <img src="media/image/logo.png" alt="logo" />
                                </div>
                                {/* <!-- ./ logo --> */}


                                <h5>Se connecter</h5>
                                {error && <Message variant='danger'>{error}</Message>}
                                {loading && <Loader />}
                                {/* <!-- form --> */}
                                <form onSubmit={handleOnSubmit}>
                                    <div className="form-group">
                                        <input 
                                            type="email" 
                                            className="form-control" 
                                            defaultValue={email}
                                            placeholder="Email" 
                                            required 
                                            autoFocus onChange={handleChangeEmail} 
                                        />
                                    </div>
                                    <div className="form-group">
                                        
                                        <input type="password" className="form-control" placeholder="Password" required  onChange={handleChangePassword} />
                                    </div>
                                    <div className="form-group d-flex justify-content-between">
                                        <div className="custom-control custom-checkbox">
                                            <input 
                                                id="customCheck1"
                                                type="checkbox"  
                                                className="custom-control-input" 
                                                onChange={(e) => setIsRemember(e.target.checked)} 
                                                defaultChecked={isRemember} 
                                            />
                                            <label className="custom-control-label" htmlFor="customCheck1">Se souvenir de moi</label>
                                        </div>
                                        <div style={{fontSize:'13px', paddingTop: '2px'}}><Link to='/forget'>Mot de passe oublié ?</Link></div>
                                    </div>
                                    <button className="btn btn-primary btn-block" type="submit" disabled={loading ? true: false}> Se connecter</button>
                                    
                                </form>
                                {/* <!-- ./ form --> */}

                            </div>
                        
                        </div>
                
            }
        </>
    )
}

export default Login;