import {
    ROLE_LIST_REQUEST, ROLE_LIST_SUCCESS, ROLE_LIST_FAIL
} from './types'
import {
    ROLE_DETAILS_REQUEST, ROLE_DETAILS_SUCCESS, ROLE_DETAILS_FAIL, ROLE_DETAILS_RESET, 
    ROLE_CREATION_REQUEST, ROLE_CREATION_SUCCESS, ROLE_CREATION_FAIL, ROLE_CREATION_RESET,
    ROLE_UPDATE_REQUEST, ROLE_UPDATE_SUCCESS, ROLE_UPDATE_FAIL, ROLE_UPDATE_RESET,
    ROLE_DELETE_REQUEST, ROLE_DELETE_SUCCESS, ROLE_DELETE_FAIL
} from './../RoleTypes'
import API from '../../utils/API';

export const getAllRoles = () => async(dispatch) => {
    try {

        dispatch({ type: ROLE_LIST_REQUEST })

        const { data } = await API.get(`/roles`)

        dispatch({
            type: ROLE_LIST_SUCCESS,
            payload: data.results,
        })
    } catch (error) {
        const message =
            error.response && error.response.data ?
                error.response.data.error :
                error.message
        dispatch({
            type: ROLE_LIST_FAIL,
            payload: message,
        })
    }
}
export const getRoleDetails = (id) => async(dispatch) => {
    try {
        dispatch({
            type: ROLE_DETAILS_REQUEST,
        })

        const { data } = await API.get(`/roles/${id}`)

        dispatch({
            type: ROLE_DETAILS_SUCCESS,
            payload: data.results && data.results.length > 0 ? data.results[0] : {},
        })
    } catch (error) {
        const message =
            error.response && error.response.data.error ?
            error.response.data.error :
            error.message
        dispatch({
            type: ROLE_DETAILS_FAIL,
            payload: message,
        })
    }
}

export const createRole = (roleData) => async(dispatch) => {
    try {
        dispatch({
            type: ROLE_CREATION_REQUEST,
        })

 
    const { data } = await API.post(`/roles`, roleData)
 

        dispatch({
            type: ROLE_CREATION_SUCCESS,
            payload: data.results,
        })
    } catch (error) {
        const message =
            error.response && error.response.data.error ?
            error.response.data.error :
            error.message
        dispatch({
            type: ROLE_CREATION_FAIL,
            payload: message,
        })
    }
}

 
export const updateRole = (id, roleData) => async(dispatch) => {
 
    try {
        dispatch({
            type: ROLE_UPDATE_REQUEST,
        })

 
        const { data } = await API.put(`/roles/${id}`, roleData)
 

        dispatch({
            type: ROLE_UPDATE_SUCCESS,
            payload: data.results,
        })
        dispatch({ type: ROLE_DETAILS_RESET })
    } catch (error) {
        const message =
            error.response && error.response.data ?
            error.response.data.error :
            error.message
        dispatch({
            type: ROLE_UPDATE_FAIL,
            payload: message,
        })
    }
}
export const deleteRole = (id) => async (dispatch) => {
    try {
        dispatch({
            type: ROLE_DELETE_REQUEST
        })

    await API.delete(`/roles/${id}`)

    dispatch({
        type: ROLE_DELETE_SUCCESS
    })
    } catch (error) {
        const message =
            error.response && error.response.data.error
                ? error.response.data.error
                : error.message
        dispatch({
            type: ROLE_DELETE_FAIL,
            payload: message,
        })
    }
}
